import { Box, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const PaymentSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(3)};
`;

export const BillToItem = styled(Box)`
  padding-top: ${ehiTheme.spacing(2)};
  padding-left: ${ehiTheme.spacing(3)};
  padding-right: ${ehiTheme.spacing(3)};
`;

export const BillToItemsContainer = styled(Box)`
  background-color: ${ehiTheme.ehi.palette.secondary.background};
  margin-top: ${ehiTheme.spacing(3)};
  margin-bottom: ${ehiTheme.spacing(3)};
  padding-bottom: ${ehiTheme.spacing(2)};
`;
