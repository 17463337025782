import {
  TransactionalProfileRequestContent,
  Workflow,
} from 'services/renter/transactionalProfile/transactionalProfileTypes';
import { EHI_DOMAINS, generateReferenceUrn } from 'utils/urnUtils';

export const createQuickResProfileRequestBody = (
  lastName: string,
  firstName: string,
  environment: string,
  contactType?: string,
  phone?: string,
  countryCode?: string
): TransactionalProfileRequestContent => {
  return {
    workflow: Workflow.RESERVATION,
    personalInformation: {
      name: {
        givenName: firstName,
        surname: lastName,
      },
    },
    contactInformation: phone
      ? {
          phoneNumbers: [
            {
              type: contactType,
              number: phone,
              country: countryCode
                ? generateReferenceUrn(
                    EHI_DOMAINS.location.name,
                    EHI_DOMAINS.location.country,
                    countryCode,
                    environment
                  )
                : undefined,
            },
          ],
        }
      : undefined,
  };
};
