import { FC, useCallback, useMemo } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { useMediaQuery } from '@mui/system';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { BillToAccountNumberSearch } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/BillToAccountNumberSearch';
import { AccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearch';
import { parseUrn } from 'utils/urnUtils';
import {
  BILL_TO_INITIAL_VALUES,
  BillToFormFields,
  SearchByType,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';
import * as Yup from 'yup';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';

export type BillToDialogProps = {
  onClose: () => void;
};
export const EditBillToDialog: FC<BillToDialogProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslations();
  const { setIsAccountNumberFieldHidden, isAccountNumberFieldHidden } = useEditBillToContext();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [BillToFormFields.AccountNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 3 }))
        .matches(/^[a-z0-9]*$/i, t('validation.invalidField')),
      [BillToFormFields.BillingNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 5 }))
        .max(10, t('validation.minFieldLength', { fieldLength: 10 }))
        .matches(/^[1-9]\d{4,9}$/, t('validation.invalidField')),
    });
  }, [t]);
  const resolver = useYupValidationResolver(validationSchema);
  const formMethods = useForm({
    resolver: resolver,
    defaultValues: BILL_TO_INITIAL_VALUES,
  });
  const { setValue, watch, reset } = formMethods;
  const [searchBy] = watch([BillToFormFields.SearchBy]);

  const handleClosingModal = useCallback(() => {
    formMethods.reset();
    formMethods.clearErrors();
    onClose();
  }, [formMethods, onClose]);

  const handleSelect = useCallback(
    (accountUrn: string) => {
      setIsAccountNumberFieldHidden(true);
      setValue(BillToFormFields.AccountNumber, parseUrn(accountUrn));
      setValue(BillToFormFields.HiddenAccountNumber, parseUrn(accountUrn));
    },
    [setIsAccountNumberFieldHidden, setValue]
  );

  const onBackClicked = useCallback(() => {
    setIsAccountNumberFieldHidden(false);
    reset({ ...BILL_TO_INITIAL_VALUES, searchBy: SearchByType.AccountSearch });
  }, [reset, setIsAccountNumberFieldHidden]);

  return (
    <FormProvider {...formMethods}>
      <Dialog
        PaperProps={{
          sx: {
            height: '95%',
            width: isMobileOrTablet ? '94%' : '52%',
          },
        }}
        a11yKey={'edit-bill-to'}
        open={true}
        fullScreen={true}
        title={t('rateAndBilling.billTo.billToDialogTitle')}
        showDividers
        contentPadding={0}
        actions={{
          primaryAction: {
            label: t('common.cancel'),
            onClick: onClose,
          },
        }}>
        {!isAccountNumberFieldHidden && (
          <RadioGroup row style={{ padding: ehiTheme.spacing(2) }}>
            <FormControlLabel
              name={BillToFormFields.SearchBy}
              checked={searchBy === SearchByType.AccountNumber}
              control={<Radio color='primary' />}
              label={t('rateAndBilling.accountNumber')}
              onClick={() => setValue(BillToFormFields.SearchBy, SearchByType.AccountNumber)}
            />
            <FormControlLabel
              name={BillToFormFields.SearchBy}
              checked={searchBy === SearchByType.AccountSearch}
              control={<Radio color='primary' />}
              label={t('rateAndBilling.accountSearch')}
              onClick={() => setValue(BillToFormFields.SearchBy, SearchByType.AccountSearch)}
            />
          </RadioGroup>
        )}
        {searchBy === SearchByType.AccountNumber || isAccountNumberFieldHidden ? (
          <BillToAccountNumberSearch handleCloseModal={handleClosingModal} onBackClicked={onBackClicked} />
        ) : (
          <AccountSearch handleSelect={handleSelect} />
        )}
      </Dialog>
    </FormProvider>
  );
};
