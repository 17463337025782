import { FC, useCallback, useRef } from 'react';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { useTranslations } from 'components/shared/i18n';
import { DriverForm } from 'components/flexFlow/driver/driverForm/DriverForm';
import { EhiButton } from '@ehi/ui';
import { ContentContainer } from 'components/flexFlow/driver/Driver.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';

export type EditDriverDialogProps = {
  driver: DriverData | undefined;
  open: boolean;
  onClose: (driverModified: boolean) => void;
  isAdditionalDriver: boolean;
};

export const EditDriverDialog: FC<EditDriverDialogProps> = ({
  open,
  onClose,
  driver,
  isAdditionalDriver,
}): JSX.Element => {
  const { t } = useTranslations();
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);
  const navigate = useNavigate();

  const submitForm = async () => {
    if (formRef.current) {
      await formRef.current.handleSubmit();
    }
  };

  const handleClose = useCallback(
    (driverModified: boolean) => {
      onClose(driverModified);
    },
    [onClose]
  );

  const handleChangePrimaryDriverClick = () => {
    navigate({ hash: HashPaths.ReplaceDriver }, { replace: true });
    handleClose(false);
  };

  return (
    <Dialog
      title={isAdditionalDriver ? t('driver.editAdditionalDriver') : t('driver.editPrimaryDriver')}
      titleActions={[
        <EhiButton key={'changePrimaryDriver'} variant='text' onClick={handleChangePrimaryDriverClick}>
          {isAdditionalDriver ? t('driver.makePrimaryDriver') : t('driver.changePrimaryDriver')}
        </EhiButton>,
      ]}
      open={open}
      onClose={() => handleClose(false)}
      contentPadding={0}
      a11yKey='content'
      showDividers
      maxWidth={'md'}
      fullWidth
      actions={{
        primaryAction: {
          label: t('common.update'),
          onClick: submitForm,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: () => handleClose(false),
        },
      }}>
      <ContentContainer>
        <DriverForm onClose={handleClose} formRef={formRef} driver={driver} data-testId={'editDriverDialog'} />
      </ContentContainer>
    </Dialog>
  );
};
