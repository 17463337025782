import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { useStartReservationSession } from './useStartReservationSession';
import { useCallback } from 'react';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { loadEhiLocationCookie } from '@ehi/location';
import { useQuickResHelper } from 'components/quickRes/useQuickResHelper';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { useLocationQuery } from 'services/location/locationQueries';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

export const useStartQuickReservationSession = () => {
  const cookieLocation = loadEhiLocationCookie();
  const { data: locationQuery } = useLocationQuery(cookieLocation?.peoplesoftId ?? '');
  const { startOpenEditorSession } = useStartReservationSession();
  const { savePickupAndReturnLocation } = useQuickResHelper();
  const { refreshEditor } = useRefreshEditor();

  const logSetUpQuickResError = useCallback(
    (errorMessage: string, errors: ResponseMessage[] | undefined) => {
      logError({
        error: {
          message: errorMessage,
          supportInformation: {
            transactionType: TransactionTypes.CreateQuickRes,
            location: cookieLocation,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
    },
    [cookieLocation]
  );

  const createQuickResSession = useCallback(
    async (): Promise<LoadingState | undefined> =>
      startOpenEditorSession(TransactionTypes.CreateQuickRes).then(async (response) => {
        const editorId = response?.data?.editorId;
        const createEditorErrors = response?.errors;
        if (!createEditorErrors?.length && editorId) {
          if (locationQuery?.location?.urn) {
            const { errors: saveErrors } = await savePickupAndReturnLocation(editorId, locationQuery?.location?.urn);
            if (!saveErrors?.length) {
              await refreshEditor(editorId);
              return LoadingState.SUCCESS;
            } else {
              logSetUpQuickResError('Error saving pick up and return info', saveErrors);
              return LoadingState.ERROR;
            }
          } else {
            logSetUpQuickResError('Error location is not available to save pick up and return info', undefined);
          }
        } else {
          logSetUpQuickResError('Error Creating Reservation Editor', createEditorErrors);
          return LoadingState.ERROR;
        }
      }),
    [
      startOpenEditorSession,
      savePickupAndReturnLocation,
      locationQuery?.location?.urn,
      refreshEditor,
      logSetUpQuickResError,
    ]
  );
  return {
    createQuickResSession,
  };
};
