import { styled } from '@mui/material/styles';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import { ButtonGroup, DialogContent } from '@mui/material';

export const StyledEhiButton = styled(EhiButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: ${ehiTheme.spacing(1)};
  padding-right: ${ehiTheme.spacing(1)};
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;

  & .MuiButtonGroup-grouped {
    margin: 0;
  }
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledDialogContent = styled(DialogContent)({
  padding: ehiTheme.spacing(0, 2, 0.5, 1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '37.5rem',
});
export const StyledBody1 = styled(Body1)({
  marginBottom: ehiTheme.spacing(2),
  marginLeft: `-${ehiTheme.spacing(1)}`,
  width: '31.25rem',
});
