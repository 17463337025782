import { TFunction } from 'i18next';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';

export enum paymentTypes {
  Business_Account = 'BUSINESS_ACCOUNT',
  Credit_Card = 'CREDIT_CARD',
  Debit_Card = 'DEBIT_CARD',
  Other = 'UNKNOWN',
}

export type paymentOptionType = {
  key: string;
  title: string;
};

export const getPaymentTypeOptions = (t: TFunction<'translation'>): OptionItem[] => {
  return [
    { id: paymentTypes.Business_Account, label: t('payment.billTo') },
    { id: paymentTypes.Credit_Card, label: t('payment.creditCard') },
    { id: paymentTypes.Debit_Card, label: t('payment.debitCard') },
    { id: paymentTypes.Other, label: t('payment.other') },
  ];
};
