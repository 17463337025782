import { Contact, NoProfileRenter, Rate } from 'services/booking/bookingTypes';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import {
  Address,
  CreateDriverProfileRequest,
  CreateProfileLegalIdentification,
  DriverProfile,
  DriverProfilePhoneNumbers,
  LegalIdentification,
  LoyaltyMembership,
  LoyaltyProgram,
  ModifyContactInfoRequestObject,
  ModifyContactPhoneNumber,
  ModifyWholeProfileRequestObject,
  PhoneNumber,
  WarningDepartment,
} from 'services/renter/driverProfile/driverProfileTypes';
import { calculateAge, toDateTimeString } from 'utils/dateUtils';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import {
  PhoneNumber as TransactionProfilePhoneNumber,
  TransactionalProfileRequestContent,
  TransactionalProfileResponseContent,
  Workflow,
} from 'services/renter/transactionalProfile/transactionalProfileTypes';
import {
  DRIVERS_LICENSE_LEGAL_ID_TYPE,
  ID_CARD_LEGAL_ID_TYPE,
  LoyaltyProgram as LoyaltyProgramConstants,
  PASSPORT_LEGAL_ID_TYPE,
} from 'utils/constants';
import { DepartmentType, LegalIdentificationType, WarningReason } from 'services/renter/renterReferenceTypes';
import { TFunction } from 'i18next';
import { DriverWarningData } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningTypes';
import { DateTime } from 'luxon';
import { DriverFormFields, DriverFormValues } from 'components/flexFlow/driver/driverForm/DriverFormTypes';

export const formatName = (lastName?: string, firstName?: string): string => {
  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  } else if (lastName && !firstName) {
    return lastName;
  } else if (!lastName && firstName) {
    return firstName;
  }
  return '';
};

const getAddressLines = (address: Address | undefined) => {
  const addressLines = [];
  address?.addressLine1 && addressLines.push(address.addressLine1);
  address?.addressLine2 && addressLines.push(address.addressLine2);
  address?.addressLine3 && addressLines.push(address.addressLine3);
  address?.addressLine4 && addressLines.push(address.addressLine4);
  return addressLines;
};

const getCityStateZip = (address: Address | undefined) => {
  const cityStateZip = [];
  const cityString = address?.city && address.countrySubdivision ? `${address.city}, ` : address?.city;
  address?.city && cityStateZip.push(cityString);
  address?.countrySubdivision && cityStateZip.push(parseUrn(address.countrySubdivision));
  address?.postalCode && cityStateZip.push(address.postalCode);
  address?.country && cityStateZip.push(parseUrn(address.country));
  return cityStateZip.join(' ');
};

/**
 * Transforms driver info from NoProfileRenter
 * */
export const transformToDriverFromNoProfile = (noProfileRenter: NoProfileRenter, contact?: Contact): DriverData => {
  return {
    type: RenterTypes.NoProfile,
    firstName: noProfileRenter.name?.given,
    lastName: noProfileRenter.name?.surname,
    age: noProfileRenter?.age,
    email: contact?.email,
    primaryPhone: {
      number: contact?.phone?.number,
      extension: contact?.phone?.extension,
    },
    contact: {
      name: contact?.name,
    },
  };
};

/**
 * Transforms driver info from Renter Driver Profile
 * */
export const transformToDriverFromDriverProfile = (
  driverProfile: DriverProfile,
  loyaltyPrograms: LoyaltyProgram[],
  loyaltyMembership?: LoyaltyMembership,
  rate?: Rate
): DriverData => {
  const email = driverProfile.emailAddresses?.find((email) => email.priority === 1);
  const phone = driverProfile.phoneNumbers?.find((phone) => phone.priority === 1);
  const address = driverProfile.address;
  const driverData: DriverData = {
    type: RenterTypes.DriverProfile,
    urn: driverProfile.urn,
    creationDate: driverProfile.creationDate,
    firstName: driverProfile.name?.givenName,
    lastName: driverProfile.name?.surname,
    age: driverProfile?.age,
    email: email?.emailAddress,
    primaryPhone: {
      type: parseUrn(phone?.phoneType),
      typeUrn: phone?.phoneType,
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
      country: parseUrn(phone?.country),
    },
    phoneNumbers: driverProfile.phoneNumbers?.map((phone) => ({
      type: parseUrn(phone?.phoneType),
      typeUrn: phone?.phoneType,
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
      country: parseUrn(phone?.country),
    })),
    contact: {
      name: formatName(driverProfile.name?.surname, driverProfile.name?.givenName),
    },
    address: {
      lines: getAddressLines(address),
      city: address?.city ?? '',
      countrySubdivisionCode: parseUrn(address?.countrySubdivision),
      countryCode: parseUrn(address?.country),
      postalCode: address?.postalCode ?? '',
    },
    dob: driverProfile.birthInformation?.date,
    placeOfBirth: driverProfile.birthInformation?.city,
    renterWarning: driverProfile.renterWarning,
    legalIdentification: findDriverLicense(driverProfile.legalIdentifications),
    driverFees: rate,
  };
  if (!loyaltyMembership) {
    return driverData;
  }
  const { type: loyaltyType, number: loyaltyNumber, loyaltyProgram } = loyaltyMembership;
  const loyaltyProgramInfo = loyaltyPrograms.find((value) => value?.urn === loyaltyProgram);

  return {
    ...driverData,
    loyaltyType,
    loyaltyProgram: loyaltyProgram ? { urn: loyaltyProgram, name: loyaltyProgramInfo?.name } : undefined,
    loyaltyNumber,
  };
};

/**
 * Transforms driver info from People Transactional Profile
 * */
export const transformToDriverFromTransactionalProfile = (
  transactionalProfile: TransactionalProfileResponseContent,
  rate?: Rate
): DriverData => {
  const email = transactionalProfile.contactInformation?.emailAddresses?.find((email) => email.priority === 1);
  const phone = transactionalProfile.contactInformation?.phoneNumbers?.find((phone) => phone.priority === 1);
  const address = transactionalProfile.contactInformation?.address;

  return {
    type: RenterTypes.TransactionalProfile,
    urn: transactionalProfile.urn,
    firstName: transactionalProfile.personalInformation?.name?.givenName,
    lastName: transactionalProfile.personalInformation?.name?.surname,
    age: transactionalProfile?.personalInformation?.birthInformation?.date
      ? calculateAge(transactionalProfile.personalInformation.birthInformation.date)
      : undefined,
    email: email?.emailAddress,
    primaryPhone: {
      type: parseUrn(phone?.type),
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
    },
    phoneNumbers: transactionalProfile.contactInformation?.phoneNumbers?.map((phone) => ({
      type: parseUrn(phone?.type),
      typeUrn: phone?.type,
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
      country: parseUrn(phone?.country),
    })),
    contact: {
      name: formatName(
        transactionalProfile.personalInformation?.name?.surname,
        transactionalProfile.personalInformation?.name?.givenName
      ),
    },
    address: {
      lines: getAddressLines(address),
      city: address?.city ?? '',
      countrySubdivisionCode: parseUrn(address?.countrySubdivision),
      countryCode: parseUrn(address?.country),
      postalCode: address?.postalCode ?? '',
    },
    driverFees: rate,
  };
};

export const findDriverLicense = (legalIds?: LegalIdentification[]): LegalIdentification | undefined => {
  return legalIds?.find((id) => parseUrn(id.type) === DRIVERS_LICENSE_LEGAL_ID_TYPE);
};

const getDepartMentDetails = (
  departmentCode: string,
  warningDepartments: WarningDepartment[],
  departmentTypes: DepartmentType[]
) => {
  const departmentDetails = warningDepartments?.find((department) => {
    return parseUrn(department.urn) === departmentCode;
  });
  return {
    ...departmentDetails,
    description: departmentTypes?.find((departmentType) => departmentType.code === parseUrn(departmentDetails?.type))
      ?.name,
  };
};
export const unpaidBalanceReasons = [
  'FORCE_CHARGE_TSS',
  'FORCE_CHARGE_CANADA',
  'DAMAGE_CLAIM',
  'TRAFFIC_VIOLATION',
  'CITATIONS',
  'BA_OUTSTANDING_BALANCE_OTHER',
  'CHARGE_BACK',
  'ECARS_FORCE_CHARGE',
];
const ADVERSE_RISK = 'ADVERSE_RISK';

export const getDomainValueDescription = (
  domainValues: WarningReason[] | undefined,
  code: string | undefined
): string => {
  return domainValues?.find((value) => value.code === code)?.name || code || '';
};

const getWarningMessage = (warningReason: string, warningReasons: WarningReason[], t: TFunction<'translation'>) => {
  const displayUnpaidBalanceWarning = unpaidBalanceReasons.includes(warningReason);
  const displayAdverseRiskWarning = ADVERSE_RISK === warningReason;

  let warningMessage: string;
  if (displayUnpaidBalanceWarning) {
    warningMessage = t('driver.warnings.unpaidBalanceWarning');
  } else if (displayAdverseRiskWarning) {
    warningMessage = t('driver.warnings.adverseRiskWarning');
  } else {
    warningMessage = getDomainValueDescription(warningReasons, warningReason);
  }
  return warningMessage;
};
const getReason = (code: string, warningReasons: WarningReason[]) => {
  return warningReasons.find((warningReason) => warningReason.code === code)?.name;
};

export const transformToDriverWarningData = (
  driverProfile: DriverProfile,
  departmentTypes: DepartmentType[],
  warningDepartments: WarningDepartment[],
  warningReasons: WarningReason[],
  t: TFunction<'translation'>
): DriverWarningData => {
  const email = driverProfile.emailAddresses?.find((email) => email.priority === 1);
  const phone = driverProfile.phoneNumbers?.find((phone) => phone.priority === 1);
  const address = driverProfile.address;
  const legalIdentification = findDriverLicense(driverProfile?.legalIdentifications);

  return {
    name: formatName(driverProfile.name?.surname, driverProfile.name?.givenName),
    dob: toDateTimeString(driverProfile.birthInformation?.date, t('format.date')) ?? '',
    email: email?.emailAddress ?? '',
    phone: phone?.number ?? '',
    phoneType: phone?.phoneType ?? '',
    address: {
      lines: getAddressLines(address) ?? [],
      cityStateZip: getCityStateZip(address),
    },
    legalIdentification: legalIdentification ?? {},
    warningMessage: getWarningMessage(parseUrn(driverProfile?.renterWarning?.reason), warningReasons, t),
    referenceDocuments: driverProfile.renterWarning?.referenceDocuments ?? [],
    reason: getReason(parseUrn(driverProfile?.renterWarning?.reason), warningReasons) ?? '',
    message: driverProfile.renterWarning?.messages?.toString() ?? '',
    department:
      driverProfile.renterWarning?.department && warningDepartments && departmentTypes
        ? getDepartMentDetails(parseUrn(driverProfile.renterWarning?.department), warningDepartments, departmentTypes)
        : {},
    reportDate: toDateTimeString(driverProfile?.renterWarning?.reportDate, t('format.date')) ?? '',
  };
};

const generateCountryUrn = (licenseCountry: string, environment: string) =>
  generateReferenceUrn(EHI_DOMAINS.location.name, EHI_DOMAINS.location.country, licenseCountry, environment);
const generateCountrySubdivisionUrn = (licenseIssuerRegion: string | undefined, environment: string) => {
  return licenseIssuerRegion
    ? generateReferenceUrn(
        EHI_DOMAINS.location.name,
        EHI_DOMAINS.location.countrySubdivision,
        licenseIssuerRegion,
        environment
      )
    : undefined;
};

const transformToCreateProfileLegalIdentifications = (
  values: DriverFormValues,
  environment: string
): Array<CreateProfileLegalIdentification> => {
  const legalIdentifications = new Array<CreateProfileLegalIdentification>();
  const driversLicensesTypeUrn = generateReferenceUrn(
    EHI_DOMAINS.renter.name,
    EHI_DOMAINS.renter.legalIdentification,
    DRIVERS_LICENSE_LEGAL_ID_TYPE,
    environment
  );
  const {
    licenseNumber,
    licenseCountry,
    licenseIssuerRegion,
    licenseExpirationDate,
    licenseIssuedDate,
    licenseIssuerAuth,
    identificationNumber,
    idPassportNumber,
    idType,
    idIssuingCountry,
    idExpirationDate,
  } = values;

  const countryUrn = generateCountryUrn(licenseCountry, environment);
  const countrySubdivisionUrn = generateCountrySubdivisionUrn(licenseIssuerRegion, environment);

  legalIdentifications.push({
    type: driversLicensesTypeUrn,
    number: licenseNumber,
    expirationDate: licenseExpirationDate ? licenseExpirationDate.toISO() ?? undefined : undefined,
    issueDate: licenseIssuedDate ? licenseIssuedDate.toISO() ?? undefined : undefined,
    country: countryUrn,
    countrySubdivision: countrySubdivisionUrn,
    issuingAuthority: licenseIssuerAuth,
  });

  if (identificationNumber) {
    const legalIdentificationTypeUrn = generateReferenceUrn(
      EHI_DOMAINS.renter.name,
      EHI_DOMAINS.renter.legalIdentification,
      ID_CARD_LEGAL_ID_TYPE,
      environment
    );

    legalIdentifications.push({
      type: legalIdentificationTypeUrn,
      number: identificationNumber,
      country: countryUrn,
    });
  }

  if (idPassportNumber) {
    const legalIdentificationType = idType === 'passport' ? PASSPORT_LEGAL_ID_TYPE : ID_CARD_LEGAL_ID_TYPE;
    const legalIdentificationTypeUrn = generateReferenceUrn(
      EHI_DOMAINS.renter.name,
      EHI_DOMAINS.renter.legalIdentification,
      legalIdentificationType,
      environment
    );
    const issuingCountryUrn = idIssuingCountry
      ? generateReferenceUrn(EHI_DOMAINS.location.name, EHI_DOMAINS.location.country, idIssuingCountry, environment)
      : countryUrn;

    legalIdentifications.push({
      type: legalIdentificationTypeUrn,
      number: idPassportNumber,
      expirationDate: idExpirationDate ? idExpirationDate.toISO() ?? undefined : idExpirationDate,
      country: issuingCountryUrn ?? countryUrn,
    });
  }

  return legalIdentifications;
};

const transformToCreateProfileAddress = (values: DriverFormValues, environment: string): Address | undefined => {
  const { streetAddress1, streetAddress2, city, driverCountry, subRegion, postalCode } = values;
  if (!driverCountry) {
    return undefined;
  }
  const subRegionUrn =
    subRegion.length > 0
      ? generateReferenceUrn(EHI_DOMAINS.location.name, EHI_DOMAINS.location.countrySubdivision, subRegion, environment)
      : undefined;
  const countryUrn = generateReferenceUrn(
    EHI_DOMAINS.location.name,
    EHI_DOMAINS.location.country,
    driverCountry,
    environment
  );

  return {
    addressLine1: streetAddress1.length > 0 ? streetAddress1 : undefined,
    addressLine2: streetAddress2.length > 0 ? streetAddress2 : undefined,
    city: city.length > 0 ? city : undefined,
    countrySubdivision: subRegionUrn,
    country: countryUrn,
    postalCode: postalCode.length > 0 ? postalCode : undefined,
  };
};

const transformToCreateProfilePhoneNumbers = (
  values: DriverFormValues,
  environment: string,
  isTransactionProfile?: boolean
): TransactionProfilePhoneNumber[] | DriverProfilePhoneNumbers | ModifyContactPhoneNumber[] | undefined => {
  const { phone } = values;
  if (phone[0].number === '') {
    return undefined;
  }

  const phoneNumbers = new Array<PhoneNumber | TransactionProfilePhoneNumber>();
  phone.forEach((value) => {
    const phoneTypeUrn = value.type;
    const countryUrn = value.countryCode
      ? generateReferenceUrn(EHI_DOMAINS.location.name, EHI_DOMAINS.location.country, value.countryCode, environment)
      : undefined;
    if (isTransactionProfile) {
      phoneNumbers.push({
        type: phoneTypeUrn,
        number: value.number,
        country: countryUrn,
        priority: value.priority,
      });
    } else {
      phoneNumbers.push({
        phoneType: phoneTypeUrn,
        number: value.number,
        country: countryUrn,
        priority: value.priority,
      });
    }
  });

  return phoneNumbers;
};

export const transformToCreateDriverProfileRequest = (
  values: DriverFormValues,
  environment: string
): CreateDriverProfileRequest => {
  const { firstName, lastName, email, dateOfBirth, placeOfBirth } = values;
  return {
    type: LoyaltyProgramConstants.NON_LOYALTY,
    profile: {
      name: {
        givenName: firstName,
        surname: lastName,
      },
      emailAddresses:
        email.length > 0
          ? [
              {
                emailAddress: email,
              },
            ]
          : undefined,
      legalIdentifications: transformToCreateProfileLegalIdentifications(values, environment),
      address: transformToCreateProfileAddress(values, environment),
      phoneNumbers: transformToCreateProfilePhoneNumbers(values, environment),
      birthInformation: {
        date: (dateOfBirth as DateTime) ? (dateOfBirth as DateTime).toFormat('yyyy-MM-dd') : '',
        city: placeOfBirth,
      },
    },
  };
};

export const transformToModifyDriverProfileRequest = (
  values: DriverFormValues,
  environment: string
): ModifyWholeProfileRequestObject => {
  const { firstName, lastName, email, dateOfBirth, placeOfBirth } = values;
  return {
    name: {
      givenName: firstName,
      surname: lastName,
    },
    emailAddresses:
      email.length > 0
        ? [
            {
              emailAddress: email,
            },
          ]
        : undefined,
    legalIdentifications: transformToCreateProfileLegalIdentifications(values, environment),
    address: transformToCreateProfileAddress(values, environment),
    phoneNumbers: transformToCreateProfilePhoneNumbers(values, environment) as ModifyContactPhoneNumber[],
    birthInformation: {
      date: (dateOfBirth as DateTime) ? (dateOfBirth as DateTime).toFormat('yyyy-MM-dd') : '',
      city: placeOfBirth,
    },
  };
};

export const transformToModifyContactInfoRequest = (
  values: DriverFormValues,
  environment: string
): ModifyContactInfoRequestObject => {
  return {
    address: transformToCreateProfileAddress(values, environment),
    emailAddresses: values[DriverFormFields.Email] ? [{ emailAddress: values[DriverFormFields.Email] }] : undefined,
    phoneNumbers: transformToCreateProfilePhoneNumbers(values, environment, false) as ModifyContactPhoneNumber[],
  };
};

export const transformToCreateTransactionalProfileRequest = (
  values: DriverFormValues,
  environment: string
): TransactionalProfileRequestContent => {
  const {
    firstName,
    lastName,
    driverCountry,
    licenseNumber,
    licenseIssuedDate,
    licenseCountry,
    licenseExpirationDate,
    licenseIssuerRegion,
    email,
    dateOfBirth,
    phone,
    licenseIssuerAuth,
  } = values;
  return {
    workflow: Workflow.RESERVATION,
    personalInformation: {
      name: {
        givenName: firstName,
        surname: lastName,
      },
      birthInformation: dateOfBirth
        ? {
            date: dateOfBirth.toFormat('yyyy-MM-dd'),
          }
        : undefined,
    },
    contactInformation:
      driverCountry || email || phone[0]?.number
        ? {
            address: transformToCreateProfileAddress(values, environment),
            emailAddresses: email ? [{ emailAddress: email }] : undefined,
            phoneNumbers: transformToCreateProfilePhoneNumbers(
              values,
              environment,
              true
            ) as TransactionProfilePhoneNumber[],
          }
        : undefined,
    // Note:  Refactor this when transaction profile accepts other legal ids in [GRES-736]
    legalIdentification: licenseCountry
      ? {
          driversLicense: {
            number: licenseNumber ?? undefined,
            issueDate: licenseIssuedDate ? licenseIssuedDate.toFormat('yyyy-MM-dd') : undefined,
            expirationDate: licenseExpirationDate ? licenseExpirationDate.toFormat('yyyy-MM-dd') : undefined,
            issuingAuthority: licenseIssuerAuth ?? undefined,
            countrySubdivision: generateCountrySubdivisionUrn(licenseIssuerRegion, environment),
            country: generateCountryUrn(licenseCountry, environment),
          },
        }
      : undefined,
  };
};

export const transformLegalIdTypes = (data: LegalIdentificationType[]) => {
  return data?.map((item) => {
    return {
      label: item.name ?? '',
      value: item.code ?? '',
    };
  });
};
