import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { EhiButtonProps, ehiTheme } from '@ehi/ui';
import { StyledBody1, StyledDialogContent } from 'components/flexFlow/driver/Driver.styles';
import { useTranslations } from 'components/shared/i18n';
import { SELECTION_OPTION } from 'components/flexFlow/driver/driverForm/driverFormUtils';

type Option = {
  label: string;
  value: string;
};

type ConfirmationDialogProps = {
  open: boolean;
  onConfirm: (selection: string) => void;
  onCancel: () => void;
  title: string;
  description: string;
  options: Option[];
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  description,
  options,
}) => {
  const { t } = useTranslations();
  const { control, watch, reset } = useForm<{ selectedOption: string | null }>({
    defaultValues: { selectedOption: null },
  });
  const selectedOption = watch('selectedOption');

  const handleConfirm = () => {
    if (selectedOption) {
      onConfirm(selectedOption);
      reset();
    }
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      title={title}
      actions={{
        primaryAction: {
          label: t('common.confirm'),
          onClick: handleConfirm,
          overrideButtonProps: {
            color: 'primary',
            variant: 'contained',
            disabled: !selectedOption,
          } as EhiButtonProps,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: handleCancel,
        },
      }}
      a11yKey='confirmation-dialog'>
      <StyledDialogContent data-testid='dialog-content'>
        <StyledBody1 data-testid='dialog-description'>{description}</StyledBody1>
        <Controller
          name={SELECTION_OPTION}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              value={field.value || ''}
              onChange={(e) => field.onChange(e.target.value)}
              data-testid='radio-group'
              sx={{ alignItems: 'flex-start', marginLeft: ehiTheme.spacing(-0.5) }}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio data-testid={`radio-${option.value}`} />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        />
      </StyledDialogContent>
    </Dialog>
  );
};
