import { FunctionComponent } from 'react';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { useTranslations } from 'components/shared/i18n';
import { Outlet } from 'react-router-dom';
import { ResActionsProvider } from 'context/resActions/ResActionsContext';
import { StyledViewIconButton } from 'app/router/Router.styles';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { useAppSelector } from 'redux/hooks';
import { selectReservationData } from 'redux/selectors/bookingEditor';
import { TransactionTypes } from 'utils/routing/TransactionTypes';

export const ViewRouter: FunctionComponent = () => {
  const { t } = useTranslations();
  const { determineTransactionType } = useReservationSessionHelper();
  const reservationData = useAppSelector(selectReservationData);
  const transactionType =
    reservationData?.lifecycle &&
    reservationData?.status &&
    determineTransactionType(reservationData.lifecycle, reservationData.status);

  return transactionType === TransactionTypes.Modify ? (
    <ResActionsProvider label={t('common.edit')} icon={<StyledViewIconButton data-testid='viewRouterIcon' />}>
      <DynamicHelmet title={t('common.viewReservation')} />
      <Outlet />
    </ResActionsProvider>
  ) : (
    <>
      <DynamicHelmet title={t('common.viewReservation')} />
      <Outlet />
    </>
  );
};
