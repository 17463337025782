import { BusinessAccountSearchResult, SearchAccountRequest } from 'services/businessAccount/businessAccountTypes';
import { useCallback, useContext, useState } from 'react';
import { searchBusinessAccounts } from 'services/businessAccount/businessAccountService';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { AccountSearchValues } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearchTypes';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { EditBillToContext } from 'context/editBillTo/EditBillToContext';
import {
  generateAccountTypeUrns,
  getAccountTypeValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/accountSearchUtils';

export type useBusinessAccountSearchType = {
  isFetchingAccounts: boolean;
  noResults: boolean;
  tooManyResults: boolean;
  accounts: BusinessAccountSearchResult[] | undefined;
  handleSearch: (formValues: AccountSearchValues, groupOptions: OptionItem[]) => void;
  handleReset: () => void;
};

export const useBusinessAccountSearch = (): useBusinessAccountSearchType => {
  const { showAlert } = useAlert();
  const appConfig = getAppConfigCache();
  const { storePreviousSearchHistory } = useContext(EditBillToContext);
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const [data, setData] = useState<BusinessAccountSearchResult[] | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleSearch = useCallback(
    async (formValues: AccountSearchValues, groupOptions: OptionItem[]) => {
      setIsFetching(true);
      const { accountName, accountTypes, phoneNumber, groups, country } = formValues;
      try {
        const accountSearchQueryPayload = (): SearchAccountRequest => {
          return {
            pagination: {
              pageIndex: 1,
              // pagination is not returning next pageIndex, when we have more results than default size
              // To Show many results if page size is greater than 50, using page size as 51
              pageSize: MAX_PAGE_SIZE + 1,
            },
            criteria: {
              country: country,
              activeAccountsOnly: true,
              mainPhoneNumber: phoneNumber?.length > 0 ? phoneNumber : undefined,
              accountName: accountName.trim().toUpperCase(),
              owningLocation:
                groups?.length > 0 && groups?.length !== groupOptions?.length
                  ? {
                      groups: groups,
                    }
                  : undefined,
              accountTypes:
                accountTypes?.length > 0 && accountTypes?.length !== getAccountTypeValues()?.length
                  ? generateAccountTypeUrns(accountTypes, defaultEhiDatabase)
                  : undefined,
            },
          };
        };

        const response = await searchBusinessAccounts(accountSearchQueryPayload());
        setData(response?.businessAccounts ?? []);
        storePreviousSearchHistory(formValues, response?.businessAccounts);
        setIsFetching(false);
      } catch (error) {
        const catchError = safelyCatchError(error).errors?.[0];

        // Using a type any for  catchError to catch the timeout error
        await showAlert({
          variant: 'error',
          description: catchError?.localizedMessage || (catchError as any)?.backendMessages[0]?.localizedMessage || '',
        });
      } finally {
        setIsFetching(false);
      }
    },
    [defaultEhiDatabase, showAlert, storePreviousSearchHistory]
  );

  const handleReset = useCallback(() => {
    storePreviousSearchHistory(undefined);
    setData(undefined);
  }, [storePreviousSearchHistory]);

  return {
    accounts: data,
    isFetchingAccounts: isFetching,
    noResults: !!data && data?.length === 0,
    tooManyResults: !!data && data?.length > MAX_PAGE_SIZE,
    handleSearch,
    handleReset,
  };
};
