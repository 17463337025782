import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { EhiButton, H6 } from '@ehi/ui';
import {
  RateAndBillingLabel,
  RateAndBillingSectionBox,
  RateAndBillingText,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectPayers, selectRateSource } from 'redux/selectors/bookingEditor';
import { useMemo, useState } from 'react';
import { EditBillToDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialog';
import { useSelector } from 'react-redux';
import { BillingAccountPayer, EditorBusinessPayer, NegotiatedRateSource } from 'services/booking/bookingTypes';
import { mask } from 'utils/maskUtils';
import { parseUrn, parseUrnItem } from 'utils/urnUtils';
import { EditBillToProvider } from 'context/editBillTo/EditBillToContext';
import { useBillingAccountByAccountQuery } from 'services/businessAccount/accountQueries';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { AddFromRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/AddFromRateSourceDialog';
import { BILLING_ACCOUNT } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/AddFromRateSourceDialogTypes';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { logError } from 'components/shared/logger/splunkLogger';
import { createBillingAccountErrorMessage } from 'components/flexFlow/rateAndBilling/editDialogs/accountSearchUtils';

export const BillToSection = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const payer = useSelector(selectPayers);
  const [addFromRateSourceModal, setAddFromRateSourceModal] = useState(false);
  const [editBillToModal, setEditBillToModal] = useState(false);
  const rateSource = useAppSelector(selectRateSource) as NegotiatedRateSource;
  const accountNumber = parseUrn(rateSource?.account);
  const { data: availableBillingAccount, error: billingAccountError } = useBillingAccountByAccountQuery(accountNumber);

  const billingAccount = useMemo(() => {
    const businessPayer: BillingAccountPayer | undefined = payer?.business?.find(
      (businessPayer: EditorBusinessPayer) => businessPayer.type === BILLING_ACCOUNT
    ) as BillingAccountPayer;
    return businessPayer?.billingAccount;
  }, [payer?.business]);

  const billingAccountErrorMessage = useMemo(() => {
    return billingAccountError && createBillingAccountErrorMessage(billingAccountError, accountNumber);
  }, [billingAccountError, accountNumber]);

  useEffectWhen(() => {
    billingAccountErrorMessage && logError({ message: billingAccountErrorMessage });
  }, !!billingAccountErrorMessage);

  return (
    <RateAndBillingSectionBox data-testid='billToSection'>
      <Box display={'flex'}>
        <GridContainer>
          <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
        </GridContainer>
        <GridContainer justifyContent={'end'}>
          {!isReadOnly && availableBillingAccount?.urn && (
            <EhiButton onClick={() => setAddFromRateSourceModal(true)}>
              {t('rateAndBilling.billTo.rateSourceBillTo')}
            </EhiButton>
          )}
          {!isReadOnly && <EhiButton onClick={() => setEditBillToModal(true)}>{t('common.edit')}</EhiButton>}
        </GridContainer>
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {billingAccount && <RateAndBillingText bold>{parseUrnItem(billingAccount, 'account')}</RateAndBillingText>}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.billTo.billingNumber')}</RateAndBillingLabel>
          {billingAccount && <RateAndBillingText bold>{mask(parseUrn(billingAccount), 4)}</RateAndBillingText>}
        </Grid>
      </Grid>
      {addFromRateSourceModal && (
        <AddFromRateSourceDialog
          availableBillingAccount={parseUrn(availableBillingAccount?.urn)}
          onClose={() => setAddFromRateSourceModal(false)}
        />
      )}
      {editBillToModal && (
        <EditBillToProvider>
          <EditBillToDialog onClose={() => setEditBillToModal(false)} />
        </EditBillToProvider>
      )}
    </RateAndBillingSectionBox>
  );
};
