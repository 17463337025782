import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import {
  buttonImages,
  StyledFilterBox,
  StyledFilterContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { BranchLookupIconButton } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupIconButton';
import { SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { useTranslations } from 'components/shared/i18n';
import { brandOptions, distanceOptions, locationTypeOptions } from 'utils/branchLookupUtils';
import { Grid } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

type BranchLookupFilterProps = {
  onMapViewToggle: (isMap: boolean) => void;
  hasResults: boolean;
};

export const BranchLookupFilters: FC<BranchLookupFilterProps> = ({ onMapViewToggle, hasResults }) => {
  const { t } = useTranslations();
  const { setValue } = useFormContext();

  const listButtonOnClick = () => {
    setValue(BranchLookupFields.IsListButtonSelected, true);
    setValue(BranchLookupFields.IsMapButtonSelected, false);
    setValue(BranchLookupFields.SelectedBranch, undefined);
    onMapViewToggle(false);
  };

  const mapButtonOnClick = () => {
    setValue(BranchLookupFields.IsListButtonSelected, false);
    setValue(BranchLookupFields.IsMapButtonSelected, true);
    setValue(BranchLookupFields.SelectedBranch, undefined);
    onMapViewToggle(true);
  };

  const [isListButtonSelected, isMapButtonSelected, searchType] = useWatch({
    name: [
      BranchLookupFields.IsListButtonSelected,
      BranchLookupFields.IsMapButtonSelected,
      BranchLookupFields.SearchBy,
    ],
  });

  return (
    <StyledFilterBox gap={1}>
      <StyledFilterContainer>
        <Grid item style={{ paddingRight: ehiTheme.spacing(1.5) }}>
          <div>
            <BranchLookupIconButton
              name={'branchLookupListButton'}
              disabled={!hasResults}
              icon={buttonImages.listIcon}
              selected={isListButtonSelected}
              onClick={listButtonOnClick}
            />
            <BranchLookupIconButton
              name={'branchLookupMapButton'}
              disabled={!hasResults}
              icon={buttonImages.mapIcon}
              selected={isMapButtonSelected}
              onClick={mapButtonOnClick}
            />
          </div>
        </Grid>
        <Grid item style={{ paddingRight: ehiTheme.spacing(1.5) }}>
          <SelectMenu
            formFieldName={BranchLookupFields.Brands}
            label={t('location.brands')}
            primaryList={{
              list: brandOptions(t),
            }}
            selectionVariant={SelectionVariant.CHECKBOX}
            allToggleLabel={t('whenWhere.allBrands')}
            inputAllText={t('whenWhere.allBrands')}
            footerText={t('common.clear')}
            showPillInput
          />
        </Grid>
        <Grid item style={{ paddingRight: ehiTheme.spacing(1.5) }}>
          <SelectMenu
            formFieldName={BranchLookupFields.LocationTypes}
            label={t('location.locationTypes')}
            primaryList={{
              list: locationTypeOptions(t),
            }}
            selectionVariant={SelectionVariant.CHECKBOX}
            allToggleLabel={t('location.allLocationTypes')}
            inputAllText={t('location.allLocationTypes')}
            footerText={t('common.clear')}
            showPillInput
          />
        </Grid>
        {searchType === SearchByType.PostalCode && (
          <Grid item style={{ paddingRight: ehiTheme.spacing(1.5) }}>
            <SelectMenu
              formFieldName={BranchLookupFields.Distance}
              label={t('location.distance')}
              primaryList={{
                list: distanceOptions(t('location.miles')),
              }}
              selectionVariant={SelectionVariant.RADIO}
              footerText={t('common.clear')}
              showPillInput
            />
          </Grid>
        )}
      </StyledFilterContainer>
    </StyledFilterBox>
  );
};
