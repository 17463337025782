import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import {
  ADD_FROM_RATE_SOURCE_INITIAL_VALUES,
  AddFromRateSourceFormFields,
  AddFromRateSourceFormValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/AddFromRateSourceDialogTypes';
import { FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { Body1, EhiButton, ehiTheme } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import { MaskedTextField } from 'components/shared/forms/MaskedTextField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { useBillTo } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/useBillTo';
import { useAppSelector } from 'redux/hooks';
import { selectRateSource } from 'redux/selectors/bookingEditor';
import { NegotiatedRateSource } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { transformAccountDetailsFromRetrieve } from 'utils/rateAndBillingUtils';
import { useAccountContactInfoQuery } from 'services/businessAccount/accountQueries';
import { EMPTY_VALUE } from 'utils/constants';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';

type AddFromRateSourceProps = {
  availableBillingAccount: string;
  onClose: () => void;
};

export const AddFromRateSourceDialog: FC<AddFromRateSourceProps> = ({ onClose, availableBillingAccount }) => {
  const { t } = useTranslations();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [AddFromRateSourceFormFields.BillingNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 5 }))
        .max(10, t('validation.minFieldLength', { fieldLength: 10 }))
        .matches(/^[1-9]\d{4,9}$/, t('validation.invalidField')),
    });
  }, [t]);

  const resolver = useYupValidationResolver(validationSchema);
  const formMethods = useForm({
    resolver: resolver,
    defaultValues: ADD_FROM_RATE_SOURCE_INITIAL_VALUES,
  });

  useEffectOnlyOnce(() => {
    formMethods.setValue(AddFromRateSourceFormFields.BillingNumber, availableBillingAccount);
  });

  return (
    <FormProvider {...formMethods}>
      <Dialog
        title={t('rateAndBilling.billTo.billToDialogTitle')}
        a11yKey={'add-billing-account'}
        open={true}
        contentPadding={0}
        actions={{
          primaryAction: {
            label: t('common.cancel'),
            onClick: onClose,
          },
        }}>
        <BillingAccountInfo onClose={onClose} />
      </Dialog>
    </FormProvider>
  );
};

const BillingAccountInfo: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslations();
  const { clearErrors, formState, handleSubmit, reset, setValue, watch } =
    useFormContext<AddFromRateSourceFormValues>();
  const billingNumber = watch(AddFromRateSourceFormFields.BillingNumber);
  const { addOrModifyBillTo, isUpdating } = useBillTo();
  const rateSource = useAppSelector(selectRateSource) as NegotiatedRateSource;
  const accountNumber = parseUrn(rateSource?.account);
  const { rateSourceInfo } = useAccountDetails();
  const { data: contact } = useAccountContactInfoQuery(accountNumber);
  const accountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(rateSourceInfo, contact);
  }, [rateSourceInfo, contact]);

  const handleCloseModal = useCallback(() => {
    reset();
    clearErrors();
    onClose();
  }, [reset, clearErrors, onClose]);

  const onSubmit = async () => {
    await addOrModifyBillTo({
      accountNumber,
      billingNumber,
      handleCloseModal,
      fieldName: AddFromRateSourceFormFields.BillingNumber,
    });
  };

  return (
    <>
      <GridContainer style={{ padding: ehiTheme.spacing(1, 3) }}>
        <Body1 data-testid={'enterBillingNumber'}>{t('rateAndBilling.billTo.enterBillingNumber')}</Body1>
      </GridContainer>
      <Box style={{ padding: ehiTheme.spacing(1, 3) }}>
        <GridContainer
          style={{ padding: ehiTheme.spacing(2) }}
          data-testid='billingNumberContainer'
          bgcolor={'#f5f5f5'}
          paddingBottom={ehiTheme.spacing(4)}
          marginTop={'auto'}>
          <BusinessAccountCard account={accountDetails} titleColor={'black'} />
          <GridItem xs={6} sm={6} style={{ marginTop: ehiTheme.spacing(1), paddingLeft: ehiTheme.spacing(0) }}>
            <MaskedTextField
              name={AddFromRateSourceFormFields.BillingNumber}
              label={t('rateAndBilling.billTo.billingNumber')}
              required
              autoFocus={true}
              exceptLast={4}
              InputProps={{
                endAdornment: (
                  <InputIconButton
                    icon={<FieldClearIcon />}
                    label={t('common.clear')}
                    onClick={() => {
                      setValue(AddFromRateSourceFormFields.BillingNumber, EMPTY_VALUE);
                      clearErrors(AddFromRateSourceFormFields.BillingNumber);
                    }}
                    disabled={billingNumber?.length === 0}
                  />
                ),
              }}
            />
          </GridItem>
          <Grid item xs={3} sm={3}>
            <EhiButton
              data-testid='billingNumber-applyButton'
              variant='contained'
              style={{ marginTop: 0 }}
              disabled={!formState.isValid || !billingNumber.length}
              onClick={handleSubmit(onSubmit)}>
              {t('common.apply')}
            </EhiButton>
          </Grid>
        </GridContainer>
      </Box>
      <ProgressOverlay inProgress={isUpdating} />
    </>
  );
};
