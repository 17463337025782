import { parseUrn, parseUrnItem } from 'utils/urnUtils';
import {
  useGetAdditionalDriversQueries,
  useGetAdditionalTransactionalDriverQueries,
} from './driverProfile/driverProfileQueries';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectAdditionalDrivers, selectAddons } from 'redux/selectors/bookingEditor';
import {
  transformToDriverFromDriverProfile,
  transformToDriverFromTransactionalProfile,
} from 'components/shared/uiModels/driver/driverTransformer';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { Rate } from 'services/booking/bookingTypes';

export type useGetAdditionalDriverProfilesTypes = {
  data: DriverData[];
  additionalDriversErrors: ResponseMessage[] | undefined;
  additionalDriversFetching: boolean;
  refetchAdditionalDrivers: () => void;
};

export const useGetAdditionalDriverProfiles = (): useGetAdditionalDriverProfilesTypes => {
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const addOns = useAppSelector(selectAddons);
  const additionalDriverAddOns = addOns?.ancillaryFees?.applied?.additionalDriver;

  const additionalDriverFee: Rate | undefined = useMemo(() => {
    return additionalDriverAddOns && additionalDriverAddOns[0]?.rate;
  }, [additionalDriverAddOns]);

  const noProfileDrivers = useMemo(() => {
    if (additionalDrivers && additionalDrivers?.length > 0) {
      return additionalDrivers?.filter((driver) => !driver.profile);
    }
    return [];
  }, [additionalDrivers]);

  const noProfileDriverData: DriverData[] | undefined = useMemo(() => {
    return noProfileDrivers?.map(() => {
      return {
        driverFees: additionalDriverFee,
      };
    });
  }, [noProfileDrivers, additionalDriverFee]);

  const transactionalProfileIds = useMemo(() => {
    if (additionalDrivers && additionalDrivers?.length > 0) {
      return additionalDrivers
        .filter((driver) => !!driver.profile && parseUrnItem(driver.profile, 'transactionalProfile'))
        ?.map((driver) => parseUrn(driver.profile));
    }
    return [];
  }, [additionalDrivers]);

  const driverProfilesIds = useMemo(() => {
    if (additionalDrivers && additionalDrivers?.length > 0) {
      return additionalDrivers
        .filter((driver) => !!driver.profile && parseUrnItem(driver.profile, 'driverProfile'))
        ?.map((driver) => parseUrn(driver.profile));
    }
    return [];
  }, [additionalDrivers]);

  const {
    data: driverProfiles,
    isFetching: isFetchingDriverProfiles,
    errors: driverProfileError,
    refetch,
  } = useGetAdditionalDriversQueries(driverProfilesIds);
  const {
    data: transactionalProfiles,
    isFetching: isFetchingTransactionalProfiles,
    errors: transactionalProfileError,
  } = useGetAdditionalTransactionalDriverQueries(transactionalProfileIds);

  const driverProfileData: DriverData[] | undefined = useMemo(
    () =>
      driverProfiles?.map((driverProfileData) =>
        transformToDriverFromDriverProfile(driverProfileData, [], undefined, additionalDriverFee)
      ),
    [driverProfiles, additionalDriverFee]
  );

  const transactionalProfileData: DriverData[] | undefined = useMemo(
    () =>
      transactionalProfiles?.map((driverProfileData) =>
        transformToDriverFromTransactionalProfile(driverProfileData, additionalDriverFee)
      ),
    [transactionalProfiles, additionalDriverFee]
  );

  const additionalDriversFetching = !!isFetchingDriverProfiles || !!isFetchingTransactionalProfiles;
  const additionalDriversErrors = driverProfileError || transactionalProfileError;

  const additionalDriverData = [
    ...(driverProfileData ?? []),
    ...(transactionalProfileData ?? []),
    ...(noProfileDriverData ?? []),
  ];

  return {
    data: additionalDriverData,
    additionalDriversErrors,
    additionalDriversFetching,
    refetchAdditionalDrivers: refetch,
  };
};
