import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2, H6, Subtitle1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { selectIsReadOnlyFlow, selectPayers } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { parseUrn, parseUrnItem } from 'utils/urnUtils';
import { useMemo, useState } from 'react';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { useForm } from 'react-hook-form';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { useSavePayment } from 'components/flexFlow/payment/useSavePayment';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { getPaymentTypeOptions } from 'utils/paymentUtils';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { Box } from '@mui/system';
import { BillToItem, BillToItemsContainer, PaymentSectionBox } from 'components/flexFlow/payment/Payment.styles';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { BillingAccountPayer, EditorBusinessPayer } from 'services/booking/bookingTypes';

export const Payment: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { save } = useSavePayment();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const payers = useAppSelector(selectPayers);
  const [loading, setLoading] = useState(false);

  const billingAccount = useMemo(() => {
    const businessPayer: BillingAccountPayer | undefined = payers?.business?.find(
      (businessPayer: EditorBusinessPayer) => businessPayer.type === 'BILLING_ACCOUNT'
    ) as BillingAccountPayer;
    return businessPayer?.billingAccount;
  }, [payers?.business]);

  const { data: billToAccount } = useBusinessAccountQuery(parseUrnItem(billingAccount, 'account'));

  const formMethods = useForm<PaymentValues>({
    defaultValues: {
      paymentType: parseUrn(payers?.person?.paymentMethod) ?? EMPTY_VALUE,
    },
    resolver: undefined,
  });

  const getSelectedValue = () => {
    const returnValue = getPaymentTypeOptions(t).find(
      (value) => value?.id === formMethods.watch(PaymentFields.PaymentType)
    )?.label;
    if (returnValue === undefined) {
      return EMPTY_VALUE;
    }

    return returnValue;
  };

  const handleSubmit = async (values: PaymentValues) => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <SavingFormProvider {...formMethods} submitFn={(values: PaymentValues) => handleSubmit(values)}>
        <CardContent>
          <FlexiFlowCardInnerContainer style={{ padding: 0 }}>
            {billToAccount && (
              <>
                <PaymentSectionBox data-testid='billToSection'>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
                  </Box>
                  <BillToItemsContainer>
                    <BillToItem>
                      <Subtitle1 bold>{billToAccount?.name}</Subtitle1>
                      <Body2>{t('payment.directBill')}</Body2>
                    </BillToItem>
                  </BillToItemsContainer>
                </PaymentSectionBox>
                <EhiDivider />
              </>
            )}
            <PaymentSectionBox>
              <Box display={'flex'} justifyContent={'space-between'}>
                <H6>{t('common.renter')}</H6>
              </Box>

              <Box my={3}>
                {readOnly ? (
                  <>
                    <Caption2>{t('payment.paymentMethod')}</Caption2>
                    <Body1> {getSelectedValue() ?? DOUBLE_DASH}</Body1>
                  </>
                ) : (
                  <SelectMenu
                    formFieldName={PaymentFields.PaymentType}
                    label={t('payment.paymentMethod')}
                    data-testid='paymentDopDown'
                    primaryList={{
                      list: getPaymentTypeOptions(t),
                    }}
                    textColor={'#4D789D'}
                  />
                )}
              </Box>
            </PaymentSectionBox>
            <ProgressOverlay inProgress={loading} />
          </FlexiFlowCardInnerContainer>
        </CardContent>
      </SavingFormProvider>
    </FlexFlowCard>
  );
};
