import { FC, useMemo, useState } from 'react';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import ListItem from '@mui/material/ListItem';
import { piiField } from '@ehi/support';
import { parseUrn } from 'utils/urnUtils';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { DriverListItem, FlexContainer, StyledGridItem } from './DriverSearch.styles';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { toDateTimeString } from 'utils/dateUtils';
import { findDriverLicense } from 'components/shared/uiModels/driver/driverTransformer';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { RentalHeaderH6 } from 'components/customerHeader/CustomerHeader.styles';
import { RenterWarningDialog } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningDialog';
import { useGetLoyaltyProgram } from 'services/renter/driverProfile/driverProfileQueries';
import { PhoneType } from 'services/renter/renterReferenceTypes';
import { Grid } from '@mui/material';
import { getCountry, getSubdivision } from 'utils/countryUtils';
import { useCountriesQuery, useSubdivisionsQuery } from 'services/location/locationQueries';

export interface DriverResultsParams {
  searchResult: SearchResult;
  onClose: () => void;
  onAddDriver?: (driver: SearchResult) => void;
  testId?: string;
  phoneTypeDomain?: PhoneType[];
}

const DriverSearchResult: FC<DriverResultsParams> = ({
  searchResult,
  onAddDriver,
  testId,
  onClose,
  phoneTypeDomain,
}: DriverResultsParams) => {
  const { t } = useTranslations();
  const { data: loyaltyPrograms } = useGetLoyaltyProgram();
  const [showRenterWarningDialog, setShowRenterWarningDialog] = useState(false);
  const driverLicenseInformation = findDriverLicense(searchResult.legalIdentifications);
  const { data: countries } = useCountriesQuery();
  const { data: subDivisions } = useSubdivisionsQuery(parseUrn(driverLicenseInformation?.country));
  const loyaltyProgramName = useMemo(() => {
    return loyaltyPrograms?.find((value) => value.urn === searchResult.loyaltyMembership?.loyaltyProgram)?.name;
  }, [loyaltyPrograms, searchResult.loyaltyMembership?.loyaltyProgram]);
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.urn === searchResult?.phoneNumbers?.[0]?.phoneType)?.name;
  }, [phoneTypeDomain, searchResult?.phoneNumbers]);

  return (
    <ListItem divider component={DriverListItem} data-testid={testId} style={{ padding: ehiTheme.spacing(2, 2, 3) }}>
      <FlexContainer>
        <Grid container paddingBottom={ehiTheme.spacing(1)}>
          {searchResult.renterWarningFlag && (
            <StyledWarningIcon data-testid='renterWarning' sx={{ paddingTop: ehiTheme.spacing(0.2) }} />
          )}
          <RentalHeaderH6 bold>
            {searchResult?.name?.surname}, {searchResult.name?.givenName}
          </RentalHeaderH6>
        </Grid>
      </FlexContainer>
      <GridContainer className={piiField} justifyContent={'space-between'}>
        <StyledGridItem sm={4}>
          <Body2 color='textPrimary'>{searchResult.address?.addressLine1}</Body2>
          <Body2 color='textPrimary'>
            {searchResult?.address?.city}, {parseUrn(searchResult?.address?.countrySubdivision)}{' '}
            {searchResult?.address?.postalCode} {parseUrn(searchResult?.address?.country)}
          </Body2>
          {phoneType && (
            <Body2 color='textPrimary'>
              {`${phoneType}: ${formatPhoneNumber(searchResult?.phoneNumbers?.[0].number) ?? ''}`}
            </Body2>
          )}
          <Body2 color='textPrimary' style={{ wordBreak: 'break-all' }}>
            {`${t('driverSearch.email')} ${formatPhoneNumber(searchResult?.emailAddresses?.[0].emailAddress) ?? ''}`}
          </Body2>
        </StyledGridItem>
        <StyledGridItem sm={3}>
          <Body2 display='inline' color='textPrimary'>
            {t('driverSearch.dlSuffix')}
          </Body2>{' '}
          <Body2 display='inline' color='textPrimary'>
            {driverLicenseInformation?.number}
          </Body2>
          <Body2 color='textPrimary'>
            {driverLicenseInformation?.countrySubdivision
              ? getSubdivision(subDivisions, driverLicenseInformation?.countrySubdivision)?.name
              : ''}
          </Body2>
          <Body2 color='textPrimary'>{getCountry(countries, parseUrn(driverLicenseInformation?.country))?.name}</Body2>
          <Body2 display='inline' color='textPrimary'>
            {t('driverSearch.dlExp')}
          </Body2>{' '}
          <Body2 display='inline' color='textPrimary'>
            {toDateTimeString(driverLicenseInformation?.expirationDate, t('format.date'))}
          </Body2>
          <br />
          <Body2 display='inline' color='textPrimary'>
            {t('driverSearch.dob')}
          </Body2>{' '}
          <Body2 display='inline' color='textPrimary'>
            {toDateTimeString(searchResult.birthInformation?.date, t('format.date'))}
          </Body2>
        </StyledGridItem>
        <StyledGridItem sm={3}>
          <LoyaltyProgramLogo
            membershipProgram={parseUrn(searchResult.loyaltyMembership?.loyaltyProgram)}
            height={18}
            width={64}
          />
          <br />
          {loyaltyProgramName && <Body2 display='inline'>{loyaltyProgramName}</Body2>}
          <br />
          <Body2 display='inline'>{searchResult.loyaltyMembership?.number}</Body2>
        </StyledGridItem>
        <StyledGridItem sm={1} justifyContent={'center'} display={'flex'} alignItems={'end'}>
          <EhiButton
            style={{ margin: ehiTheme.spacing(0), padding: ehiTheme.spacing(0) }}
            onClick={() => {
              if (searchResult.renterWarningFlag) {
                setShowRenterWarningDialog(true);
              } else {
                onAddDriver?.(searchResult);
              }
            }}>
            {t('driverSearch.add')}
          </EhiButton>
        </StyledGridItem>
      </GridContainer>
      {showRenterWarningDialog && (
        <RenterWarningDialog
          searchResult={searchResult}
          onCancel={() => {
            setShowRenterWarningDialog(false);
            onClose();
          }}
          onSubmit={() => {
            onAddDriver?.(searchResult);
          }}
        />
      )}
    </ListItem>
  );
};

export default DriverSearchResult;
