import { useMemo } from 'react';
import { QueryParamsType } from 'services/renter/transactionalProfile/transactionalProfileTypes';

type UseMemoizedOptionsResult = {
  options: any[];
  isLoading: boolean;
};

type UseMemoizedStringResult = {
  string: string;
  isLoading: boolean;
};

export const useMemoizedOptions = (
  query: QueryParamsType,
  formatter: any,
  extraParam?: any
): UseMemoizedOptionsResult => {
  const { data, isFetching } = query;

  return useMemo(() => {
    return {
      options: extraParam ? formatter(data, extraParam) : formatter(data),
      isLoading: isFetching,
    };
  }, [extraParam, formatter, data, isFetching]);
};

export const useMemoizedString = (
  query: QueryParamsType,
  formatter: any,
  extraParam?: any
): UseMemoizedStringResult => {
  const { data, isFetching } = query;

  return useMemo(() => {
    return {
      string: extraParam ? formatter(data, extraParam) : formatter(data),
      isLoading: isFetching,
    };
  }, [extraParam, formatter, data, isFetching]);
};
