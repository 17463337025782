import { H6 } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import {
  selectHasDatetimeData,
  selectHasLocationData,
  selectIsReadOnlyFlow,
  selectRateSource,
} from 'redux/selectors/bookingEditor';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import {
  RateAndBillingLabel,
  RateAndBillingSectionBox,
  RateAndBillingText,
  StyledEhiEditButton,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { RateSourceInfo } from 'components/flexFlow/rateAndBilling/RateSourceInfo';
import { useState } from 'react';
import { EditRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialog';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

export const RateSourceSection = () => {
  const { t } = useTranslations();
  const [showRateSourceModal, setShowRateSourceModal] = useState(false);

  const hasLocationData = useAppSelector(selectHasLocationData);
  const hasDatetimeData = useAppSelector(selectHasDatetimeData);
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const { rateSourceInfo, isRateSourceInfoLoading } = useAccountDetails();
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const accountNumber = negotiatedRateSource ? parseUrn(negotiatedRateSource.account) : undefined;
  const rateProduct = parseUrn(rateSource?.rateProduct);

  return (
    <RateAndBillingSectionBox data-testid='rateSourceSection'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <H6>{t('rateAndBilling.rateSource')}</H6>
        {!isReadOnly && (
          <StyledEhiEditButton onClick={() => setShowRateSourceModal(true)}>{t('common.edit')}</StyledEhiEditButton>
        )}
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {accountNumber && <RateAndBillingText data-testid='textBody'>{accountNumber}</RateAndBillingText>}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='rateProduct'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.rateProduct')}</RateAndBillingLabel>
          {rateProduct && <RateAndBillingText data-testid='textBody'>{rateProduct}</RateAndBillingText>}
        </Grid>
      </Grid>
      {hasLocationData && hasDatetimeData && (
        <RateSourceInfo accountName={rateSourceInfo?.name} accountType={rateSourceInfo?.type} />
      )}
      <EditRateSourceDialog open={showRateSourceModal} closeModal={() => setShowRateSourceModal(false)} />
      <ProgressOverlay inProgress={isRateSourceInfoLoading} />
    </RateAndBillingSectionBox>
  );
};
