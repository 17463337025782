import { Tab, Tabs } from '@mui/material';
import { ActionOptions, BaseDialogProps } from '@ehi/ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from 'components/shared/ui/dialogs/TabPanel';
import { TabBar } from 'components/shared/ui/styles/Global.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';

export type TabOption = {
  label: string;
  content: JSX.Element;
  actions: ActionOptions;
};

type TabbedDialogProps = BaseDialogProps & {
  onClose: () => void;
  tabOptions: TabOption[];
  // Use if parent needs to control tab navigation
  selectedTab?: number;
  onTabChange?: (index: number) => void;
  showDividers?: boolean;
};

export const TabbedDialog = ({
  open,
  onClose,
  title,
  titleActions,
  tabOptions,
  selectedTab,
  onTabChange,
  contentPadding,
  showDividers,
}: TabbedDialogProps): JSX.Element => {
  const [tabValue, setTabValue] = useState(selectedTab ?? 0);

  useEffect(() => {
    if (selectedTab !== undefined) {
      setTabValue(selectedTab);
    }
  }, [selectedTab]);

  const handleChange = (_event: ChangeEvent<any>, newValue: number) => {
    onTabChange ? onTabChange(newValue) : setTabValue(newValue);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      showDividers={showDividers ?? false}
      a11yKey='tabbed'
      open={open}
      onClose={onClose}
      title={title}
      data-testid={`${title}-dialog`}
      titleActions={titleActions}
      actions={tabOptions[tabValue].actions}
      contentPadding={contentPadding}
      additionalHeader={
        <>
          <TabBar position='static' color='transparent'>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label='dialog tabs'
              indicatorColor='primary'
              textColor='primary'>
              {tabOptions.map((value, index) => (
                <Tab
                  key={index}
                  label={value.label}
                  id={`dialog-tab-${index}`}
                  aria-controls={`dialog-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </TabBar>
        </>
      }>
      {tabOptions.map((value, index) => (
        <TabPanel key={index} value={tabValue} index={index} style={{ minHeight: '50vh' }}>
          {tabValue === index && value.content}
        </TabPanel>
      ))}
    </Dialog>
  );
};
