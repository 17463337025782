import { FC, useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import {
  AccountNumberField,
  AccountNumberSearchContainer,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Body1, Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import {
  BillToFormFields,
  BillToFormValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { useFormContext } from 'react-hook-form';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  useAccountContactInfoQuery,
  useBillingAccountByAccountQuery,
  useBusinessAccountQuery,
} from 'services/businessAccount/accountQueries';
import { useBillTo } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/useBillTo';
import { parseUrn } from 'utils/urnUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { MaskedTextField } from 'components/shared/forms/MaskedTextField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { transformAccountDetailsFromRetrieve } from 'utils/rateAndBillingUtils';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';

export type BillingAccountNumberSearchProps = {
  handleCloseModal: () => void;
  onBackClicked?: () => void;
};

export const BillToAccountNumberSearch: FC<BillingAccountNumberSearchProps> = ({
  handleCloseModal,
  onBackClicked,
}): JSX.Element => {
  const { t } = useTranslations();
  const { isAccountNumberFieldHidden } = useEditBillToContext();
  const { setValue, getValues, setError, watch, clearErrors, trigger } = useFormContext();
  const accountNumber = watch(BillToFormFields.AccountNumber);

  return (
    <Box>
      {isAccountNumberFieldHidden ? (
        <EhiButton variant={'text'} onClick={onBackClicked} startIcon={<ArrowBackIcon />}>
          <Body2>{t('common.back')}</Body2>
        </EhiButton>
      ) : (
        <AccountNumberSearchContainer data-testid='billingAccountNumberContainer' paddingBottom={ehiTheme.spacing(2)}>
          <Grid item>
            <Body1>{t('rateAndBilling.enterAccountNumber')}</Body1>
          </Grid>
          <Grid item>
            <AccountNumberField data-testid='searchField'>
              <Grid item xs={6} sm={6}>
                <FormTextField
                  name={BillToFormFields.AccountNumber}
                  label={t('rateAndBilling.accountNumber')}
                  data-testid='input'
                  fullWidth
                  required
                  onChange={(e) => setValue(BillToFormFields.AccountNumber, e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={() => {
                          setValue(BillToFormFields.AccountNumber, EMPTY_VALUE);
                          setValue(BillToFormFields.HiddenAccountNumber, EMPTY_VALUE);
                          clearErrors(BillToFormFields.AccountNumber);
                        }}
                        disabled={accountNumber?.length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='account-applyButton'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  disabled={!accountNumber || accountNumber.length === 0}
                  onClick={async () => {
                    // Note: manually trigger validation as form submit validation doesn't happen for AccountNumber
                    // If account number is invalid, there is no reason to make the API call triggered by setting these values
                    const isValid = await trigger([BillToFormFields.AccountNumber]);
                    if (isValid) {
                      setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
                      setValue(BillToFormFields.HiddenAccountNumber, getValues(BillToFormFields.AccountNumber));
                    } else {
                      setError(BillToFormFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
                    }
                  }}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </AccountNumberField>
          </Grid>
        </AccountNumberSearchContainer>
      )}
      {/* Hidden account number is used so that we can trigger the query without searching on every keystroke */}
      <BillingAccountInfo handleCloseModal={handleCloseModal} />
    </Box>
  );
};

const BillingAccountInfo: FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { watch, setValue, clearErrors, setError, handleSubmit, formState } = useFormContext<BillToFormValues>();
  const billingNumberHasError = formState.errors[BillToFormFields.BillingNumber] !== undefined;
  const billingNumber = watch(BillToFormFields.BillingNumber);
  const accountNumber = watch(BillToFormFields.HiddenAccountNumber);
  const { data: account, error: accountErrors, isFetching: accountLoading } = useBusinessAccountQuery(accountNumber);
  const {
    data: billingAccount,
    error: billingAccountErrors,
    isFetching: billingAccountLoading,
  } = useBillingAccountByAccountQuery(accountNumber);
  const { data: contact } = useAccountContactInfoQuery(accountNumber);
  const { checkBillingAccountErrors, addOrModifyBillTo, isUpdating } = useBillTo();

  const accountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(account, contact);
  }, [account, contact]);

  useEffect(() => {
    if (billingAccount && billingAccount.urn) {
      setValue(BillToFormFields.BillingNumber, parseUrn(billingAccount.urn), { shouldValidate: true });
    } else if (!billingAccount && billingAccountErrors) {
      checkBillingAccountErrors(billingAccountErrors);
    }
  }, [billingAccountErrors, billingAccount, setValue, checkBillingAccountErrors, setError]);

  useEffectWhen(() => {
    // if not billing account errors, then show account errors
    if (!billingAccountErrors) {
      if (accountErrors?.status === HttpStatusCode.NotFound) {
        setError(BillToFormFields.AccountNumber, { message: accountErrors?.errors?.[0].localizedMessage });
      } else {
        void showAlert({
          variant: 'error',
          responseMessages: accountErrors?.errors,
        });
      }
    }
  }, !!accountErrors);

  const onSubmit = async () => {
    await addOrModifyBillTo({
      accountNumber,
      billingNumber,
      handleCloseModal,
      fieldName: BillToFormFields.BillingNumber,
    });
  };

  return billingAccount || account ? (
    <>
      <GridContainer style={{ padding: ehiTheme.spacing(2) }}>
        {accountDetails && <BusinessAccountCard account={accountDetails} titleColor={'black'} />}
      </GridContainer>
      {billingAccountErrors?.status !== HttpStatusCode.NotFound && (
        <Box>
          <GridContainer
            data-testid='billingNumberContainer'
            bgcolor={'#f5f5f5'}
            paddingBottom={ehiTheme.spacing(4)}
            alignItems={'center'}
            marginTop={'auto'}>
            <Grid item>
              <Body1>{t('rateAndBilling.billTo.enterBillingNumber')}</Body1>
            </Grid>
            <GridItem xs={6} sm={6} style={{ marginTop: ehiTheme.spacing(1), paddingLeft: ehiTheme.spacing(2) }}>
              <MaskedTextField
                name={BillToFormFields.BillingNumber}
                label={t('rateAndBilling.billTo.billingNumber')}
                required
                autoFocus={!!account}
                exceptLast={4}
                inputProps={{
                  minLength: 5,
                  maxLength: 10,
                }}
                InputProps={{
                  endAdornment: (
                    <InputIconButton
                      icon={<FieldClearIcon />}
                      label={t('common.clear')}
                      onClick={() => {
                        setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
                        clearErrors(BillToFormFields.BillingNumber);
                      }}
                      disabled={billingNumber?.length === 0}
                    />
                  ),
                }}
              />
            </GridItem>
            <Grid item xs={3} sm={3}>
              <EhiButton
                data-testid='billingNumber-applyButton'
                variant='contained'
                style={{ marginTop: 0 }}
                disabled={billingNumberHasError || !billingNumber}
                onClick={handleSubmit(onSubmit)}>
                {t('common.apply')}
              </EhiButton>
            </Grid>
          </GridContainer>
        </Box>
      )}
      <ProgressOverlay inProgress={accountLoading || billingAccountLoading || isUpdating} />
    </>
  ) : (
    <></>
  );
};
