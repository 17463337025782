import { FC, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { SelectField } from 'components/shared/forms/SelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { QuickResHeader, StyledDivider } from 'components/quickRes/QuickRes.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext, useWatch } from 'react-hook-form';
import { updateContactInformation } from 'services/booking/bookingService';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { loadEhiLocationCookie } from '@ehi/location';
import { useCountriesQuery, useLocationQuery } from 'services/location/locationQueries';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import {
  generateOptionItemsWithCorporateCountries,
  generateOptionItemsWithNonCorporateCountries,
} from 'utils/formUtils';
import { SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { useMemoizedString } from 'hooks/useMemoizedOptions';
import { getDefaultCountry } from 'utils/locationUtils';
import { EMPTY_VALUE } from 'utils/constants';

const QuickResContact: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [isContactUpdating, setIsContactUpdating] = useState<boolean>(false);

  const { data: phoneTypeDomain, isFetching: isPhoneTypeLoading } = usePhoneTypesQuery();
  const { data: countries, isFetching: isCountriesFetching } = useCountriesQuery();
  const cookie = loadEhiLocationCookie();
  const { string: defaultCountry, isLoading: isLocationQueryLoading } = useMemoizedString(
    useLocationQuery(cookie?.peoplesoftId ?? ''),
    getDefaultCountry
  );

  const contactTypes = useMemo(() => {
    return (
      phoneTypeDomain?.map((phone) => ({ label: phone.name || EMPTY_VALUE, value: phone.urn || EMPTY_VALUE })) || []
    );
  }, [phoneTypeDomain]);
  const {
    getFieldState,
    trigger,
    setError,
    formState: { errors },
  } = useFormContext();
  const [countryCode, phone] = useWatch({
    name: [QuickResFields.CountryCode, QuickResFields.Phone],
  });

  const handleOnBlur = async (field: string) => {
    await trigger(field);

    if (getFieldState(field).isDirty && !errors[QuickResFields.Phone]) {
      try {
        setIsContactUpdating(true);
        const payload = {
          phone: { number: phone },
        };

        const result = await updateAndRefresh(() => updateContactInformation(bookingEditorId, payload));
        if (result?.errors?.length) {
          setError(field, { message: result?.errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(field, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      } finally {
        setIsContactUpdating(false);
      }
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <QuickResHeader data-testid={'res-contact'}>{t('driver.contact')}</QuickResHeader>
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.ContactType}
          label={t('driver.type')}
          data-testid={QuickResFields.ContactType}
          options={contactTypes || []}
          hasNoneOption={false}
        />
      </GridItem>
      <GridItem sm={4}>
        <SelectMenu
          formFieldName={QuickResFields.CountryCode}
          label={t('location.countryCode')}
          primaryList={{
            list: generateOptionItemsWithCorporateCountries(countries ?? []),
            subHeader: t('location.corporateCountries'),
          }}
          secondaryList={{
            list: generateOptionItemsWithNonCorporateCountries(countries ?? []),
            subHeader: t('location.otherCountries'),
          }}
          selectionVariant={SelectionVariant.CHECKMARK}
          searchFieldPlaceholder={t('location.searchForCountry')}
          footerText={t('common.reset')}
        />
      </GridItem>
      <GridItem sm={4}>
        <PhoneTextField
          country={countryCode ? countryCode : defaultCountry}
          name={QuickResFields.Phone}
          label={t('driver.phone')}
          data-testid={QuickResFields.Phone}
          onBlur={async () => {
            await handleOnBlur(QuickResFields.Phone);
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ paddingBottom: 0 }}>
        <StyledDivider />
      </GridItem>
      <ProgressOverlay
        inProgress={isContactUpdating || isPhoneTypeLoading || isCountriesFetching || isLocationQueryLoading}
      />
    </GridContainer>
  );
};
export default QuickResContact;
