import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { BranchLookupRadioGroup } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupRadioGroup';
import { EhiButton } from '@ehi/ui';
import {
  StyledBoxDropDownContainer,
  StyledBranchLookUpSearchBox,
  StyledButtonContainer,
  StyledSearchInput,
} from './BranchLookup.styles';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import SearchIcon from '@mui/icons-material/Search';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import {
  generateOptionItemsWithCorporateCountries,
  generateOptionItemsWithNonCorporateCountries,
} from 'utils/formUtils';
import { OptionItem, SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useMemoizedOptions, useMemoizedString } from 'hooks/useMemoizedOptions';
import { DAILY_RENTAL, getBrandOptions, getGroupOptions } from 'utils/branchLookupUtils';
import {
  useCountriesQuery,
  useLocationGroups,
  useLocationQuery,
  useRentalBrandsQuery,
} from 'services/location/locationQueries';
import { getDefaultCountry } from 'utils/locationUtils';
import { loadEhiLocationCookie } from '@ehi/location';

type BranchLookupSearchProps = {
  onSearch: () => void;
  handleReset: () => void;
};

export const BranchLookupSearch: FC<BranchLookupSearchProps> = ({ onSearch, handleReset }) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { resetField, watch } = useFormContext();
  const groupOptionsRef = useRef<OptionItem[]>([]);

  const { data: countries, isFetching: isCountriesFetching } = useCountriesQuery();
  const { string: defaultCountry, isLoading: isLocationQueryLoading } = useMemoizedString(
    useLocationQuery(cookie?.peoplesoftId ?? ''),
    getDefaultCountry
  );
  const { options: groupOptions, isLoading: isLocationGroupsLoading } = useMemoizedOptions(
    useLocationGroups(watch(BranchLookupFields.Country), DAILY_RENTAL),
    getGroupOptions
  );
  const { options: brandOptions, isLoading: isRentalBrandsLoading } = useMemoizedOptions(
    useRentalBrandsQuery(),
    getBrandOptions
  );

  const isInitialValuesLoading =
    isLocationQueryLoading || isRentalBrandsLoading || isLocationGroupsLoading || isCountriesFetching;

  useEffect(() => {
    resetField(BranchLookupFields.Country, { defaultValue: defaultCountry });
  }, [defaultCountry, resetField]);

  useEffect(() => {
    resetField(BranchLookupFields.Brands, { defaultValue: brandOptions });
  }, [brandOptions, resetField]);

  useEffect(() => {
    resetField(BranchLookupFields.Groups, { defaultValue: groupOptions.map((option) => option.id) });
    groupOptionsRef.current = groupOptions;
  }, [groupOptions, resetField]);

  const searchInput = watch(BranchLookupFields.SearchInputValue);
  const searchType = watch(BranchLookupFields.SearchBy);

  return (
    <StyledBranchLookUpSearchBox>
      <BranchLookupRadioGroup />
      <StyledSearchInput
        name={BranchLookupFields.SearchInputValue}
        data-testid={BranchLookupFields.SearchInputValue}
        placeholder={
          searchType === SearchByType.PostalCode ? t('location.postalCode') : t('whenWhere.locationSearchLabel')
        }
        InputProps={{
          startAdornment: <InputIconButton position={'start'} icon={<SearchIcon />} />,
          endAdornment: !!searchInput?.length && (
            <InputIconButton
              data-testid={BranchLookupFields.SearchInputValue.concat('ClearIcon')}
              icon={<FieldClearIcon />}
              label={t('common.clear')}
              onClick={() => resetField(BranchLookupFields.SearchInputValue)}
            />
          ),
        }}
      />
      <StyledBoxDropDownContainer>
        <SelectMenu
          formFieldName={BranchLookupFields.Country}
          label={t('location.country')}
          required
          primaryList={{
            list: generateOptionItemsWithCorporateCountries(countries ?? []),
            subHeader: t('location.corporateCountries'),
          }}
          secondaryList={{
            list: generateOptionItemsWithNonCorporateCountries(countries ?? []),
            subHeader: t('location.otherCountries'),
          }}
          selectionVariant={SelectionVariant.CHECKMARK}
          searchFieldPlaceholder={t('location.searchForCountry')}
          footerText={t('common.reset')}
          defaultSelection={defaultCountry}
        />
        <SelectMenu
          formFieldName={BranchLookupFields.Groups}
          label={t('whenWhere.groupNumber')}
          required
          primaryList={{
            list: groupOptionsRef.current ?? [],
            subHeader: t('whenWhere.groupNumbers'),
          }}
          selectionVariant={SelectionVariant.CHECKBOX}
          allToggleLabel={t('whenWhere.allGroupNumbers')}
          searchFieldPlaceholder={t('whenWhere.searchForGroupNumber')}
          footerText={t('common.reset')}
        />
        <StyledButtonContainer>
          <EhiButton data-testid={'branchLookupReset'} onClick={handleReset}>
            {t('common.reset')}
          </EhiButton>
          <EhiButton
            primary
            name={'branchLookupSearch'}
            data-testid={'branchLookupSearch'}
            onClick={onSearch}
            disabled={!searchInput}>
            {t('common.search')}
          </EhiButton>
        </StyledButtonContainer>
      </StyledBoxDropDownContainer>
      <ProgressOverlay inProgress={isInitialValuesLoading} />
    </StyledBranchLookUpSearchBox>
  );
};
