import { Fab, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const StyledFab = styled(Fab)`
  position: absolute;
  bottom: ${ehiTheme.spacing(3)};
  right: ${ehiTheme.spacing(3)};
  height: 48px;
  width: auto;
  border-radius: 10000000000px;
  background: var(--primary-text-dark, linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), #4d789d);

  /* elevation/06dp */
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 800px) {
    // This accounts for the global nav bar on tablet
    margin-bottom: ${ehiTheme.spacing(9)};
  }
`;
