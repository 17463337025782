import { Body2, ehiTheme } from '@ehi/ui';
import UpdateIcon from '@mui/icons-material/Update';
import { StyledFab } from 'context/resActions/ResActionButton.styles';
import { FC, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';

export type ResActionButtonProps = {
  label: string;
  icon: JSX.Element;
  updateFloatingButtonAction: boolean;
  handleButtonClick: () => void;
};

export const ResActionButton: FC<ResActionButtonProps> = ({
  label,
  icon,
  updateFloatingButtonAction,
  handleButtonClick,
}): JSX.Element => {
  const { t } = useTranslations();
  const resActionLabel = useMemo(() => {
    return updateFloatingButtonAction ? t('common.update') : label;
  }, [label, updateFloatingButtonAction, t]);
  const resActionIcon = updateFloatingButtonAction ? (
    <UpdateIcon style={{ paddingRight: ehiTheme.spacing(0.5) }} />
  ) : (
    icon
  );

  return (
    <StyledFab
      color='primary'
      variant={'extended'}
      data-testid={`res-${resActionLabel}-action-button`}
      onClick={handleButtonClick}>
      {resActionIcon}
      <Body2>{resActionLabel}</Body2>
    </StyledFab>
  );
};
