import { FC, useCallback, useState } from 'react';
import { AppBar, Box } from '@mui/material';
import { DriverSearchForm } from 'components/flexFlow/driver/driverSearch/DriverSearchForm';
import { useRenterProfileSearch } from 'components/flexFlow/driver/driverSearch/useRenterProfileSearch';
import { DriverSearchValues } from './DriverSearchTypes';
import { MarginWrapper, StyledList } from 'components/shared/ui/styles/Global.styles';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import DriverSearchResult from './DriverSearchResult';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { useTranslations } from 'components/shared/i18n';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { DriverProfileRenter, RateSource, Renter } from 'services/booking/bookingTypes';
import {
  associateRenterToReservationEditor,
  modifyRateSource,
  updateAdditionalDrivers,
} from 'services/booking/bookingService';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectContact,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { calculateAge, toDateTimeString, YEAR_MONTH_DAY_FORMAT } from 'utils/dateUtils';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, parseContactName, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { getDriverProfileLoyaltyMembership } from 'services/renter/driverProfile/driverProfileService';
import { parseUrn } from 'utils/urnUtils';
import { retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { Body2 } from '@ehi/ui';

export type DriverSearchProps = {
  navigateToDriverForm: (driver: DriverData | undefined) => void;
  onClose: () => void;
  isUpdatingPrimaryDriver?: boolean;
};

export const DriverSearch: FC<DriverSearchProps> = ({
  navigateToDriverForm,
  onClose,
  isUpdatingPrimaryDriver = false,
}) => {
  const { t } = useTranslations();
  const { search } = useRenterProfileSearch();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const contact = useAppSelector(selectContact);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[] | undefined>();
  const [driverData, setDriverData] = useState<DriverData | undefined>(undefined);
  const [showChangePrimaryDriverDialog, setShowChangePrimaryDriverDialog] = useState(false);
  const [driverProfile, setDriverProfile] = useState<SearchResult | undefined>(undefined);
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading } = usePhoneTypesQuery();

  const handleError = async (errors: ResponseMessage[] | undefined) => {
    if (errors) {
      setLoading(false);
      await showAlert({
        variant: 'error',
        description: `${t('driver.addDriverError')}: ${errors?.[0]?.localizedMessage || ''}`,
      });
    } else {
      onClose();
    }
  };

  const handleLogError = (error: unknown, message: string) => {
    logError({
      error: {
        message: t(message),
        supportInformation: {
          transactionType: TransactionTypes.CreateFullRes,
          location: cookieLocation,
          counter: counterCookie?.counterId,
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
    setLoading(false);
  };

  const searchForRenter = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[] | undefined> => {
      const validateDrivers = (urn: string) => {
        const prevDrivers = [...(driverProfileRenter ? [driverProfileRenter] : []), ...(additionalDrivers || [])];
        return prevDrivers.some(({ profile }) => profile === urn);
      };

      setLoading(true);
      return search(values)
        .then(async (results) => {
          const duplicateDriver = results.some((result) => result.urn && validateDrivers(result.urn));
          if (duplicateDriver) {
            await showAlert({
              variant: 'error',
              description: `${t('driverSearch.driverAlreadyExists')}`,
            });
            return;
          }

          setResults(results);
          return results;
        })
        .catch(() => {
          setResults([]);
          return [];
        })
        .finally(() => {
          if (values) {
            const dobString = toDateTimeString(values.dateOfBirth, YEAR_MONTH_DAY_FORMAT) ?? undefined;
            setDriverData({
              phoneNumbers: [{ number: values.phoneNumber }],
              firstName: values.firstName,
              lastName: values.lastName,
              age: dobString ? calculateAge(dobString) : undefined,
            });
          }
          setLoading(false);
        });
    },
    [search, driverProfileRenter, additionalDrivers, showAlert, t]
  );

  const handleAddDriver = async (driverData: SearchResult) => {
    setLoading(true);
    if (isUpdatingPrimaryDriver) {
      setShowChangePrimaryDriverDialog(true);
      setLoading(false);
      setDriverProfile(driverData);
      return;
    }
    if (!driverProfileRenter) {
      let membership;
      let membershipProgram;
      let accountDetails: ReservationAccount | undefined;
      try {
        membershipProgram = driverData?.loyaltyMembership?.loyaltyProgram;
        if (membershipProgram && driverData.urn) {
          membership = await getDriverProfileLoyaltyMembership(parseUrn(driverData.urn), parseUrn(membershipProgram));
          const alternateContractId = membership?.rateContracts?.[0]?.alternateContractId;
          if (alternateContractId) {
            accountDetails = await retrieveBusinessAccount(alternateContractId);
          }
        }
      } catch (error) {
        handleLogError(error, 'driver.fetchLoyaltyMembershipError');
        setLoading(false);
        return;
      }
      try {
        const requestBody = {
          type: 'DRIVER_PROFILE',
          profile: driverData?.urn,
          membership: membershipProgram ? { loyaltyProgram: membershipProgram } : undefined,
        } as DriverProfileRenter;

        const rateSourceRequestBody: RateSource = {
          type: 'NEGOTIATED',
          account: accountDetails?.urn ?? '',
        };
        const { errors } = await updateAndRefresh(async () => {
          await associateRenterToReservationEditor(bookingEditorId, requestBody as Renter);
          if (accountDetails) await modifyRateSource(bookingEditorId, rateSourceRequestBody);
        });
        await handleError(errors);
      } catch (error) {
        handleLogError(error, 'driver.addDriverError');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
        const requestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given: driverData.name?.givenName ?? '', surname: driverData.name?.surname ?? '' },
            profile: driverData.urn,
          },
        ];
        const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
        await handleError(errors);
      } catch (error) {
        handleLogError(error, 'driver.addAdditionalDriverError');
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const updateWithErrorHandling = async (updateFunc: () => Promise<any>, errorKey: string) => {
    try {
      const { errors } = await updateAndRefresh(updateFunc);
      await handleError(errors);
    } catch (error) {
      handleLogError(error, errorKey);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmChangePrimaryDriver = async (selection: string) => {
    setShowChangePrimaryDriverDialog(false);
    setLoading(true);
    if (selection === REMOVE && driverProfile) {
      const primaryDriverRequestBody = createDriverProfileRequestBody(driverProfile);
      await updateWithErrorHandling(
        () => associateRenterToReservationEditor(bookingEditorId, primaryDriverRequestBody as Renter),
        'driver.removePrimaryDriverError'
      );
    } else {
      if (driverProfileRenter && driverProfile) {
        const { given, surname } = parseContactName(contact?.name ?? '');

        const primaryDriverRequestBody = createDriverProfileRequestBody(driverProfile);

        await updateWithErrorHandling(
          () => associateRenterToReservationEditor(bookingEditorId, primaryDriverRequestBody),
          'driver.associatePrimaryDriverError'
        );

        const currentAdditionalDrivers = additionalDrivers?.length ? additionalDrivers : [];
        const additionalDriverRequestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given, surname },
            profile: driverProfileRenter?.profile,
          },
        ];

        await updateWithErrorHandling(
          () => updateAdditionalDrivers(bookingEditorId, additionalDriverRequestBody),
          'driver.updateAdditionalDriversError'
        );
      }
    }
  };

  const createDriverProfileRequestBody = (driverProfile: SearchResult) => {
    return {
      type: 'DRIVER_PROFILE' as const,
      profile: driverProfile?.urn,
      membership: driverProfile?.loyaltyMembership?.loyaltyProgram
        ? { loyaltyProgram: driverProfile.loyaltyMembership.loyaltyProgram }
        : undefined,
    } as Renter;
  };

  const handleCancelChangePrimaryDriver = () => {
    setShowChangePrimaryDriverDialog(false);
  };

  return (
    <>
      <AppBar position='sticky' color='inherit' style={{ boxShadow: 'none' }}>
        <DriverSearchForm search={searchForRenter} />
      </AppBar>
      <MarginWrapper>
        {results && results.length > 0 && (
          <StyledList>
            <Body2 data-testid='result-header'>{`${results.length} ${t('driverSearch.results')}`} </Body2>
            <DividerWithMargin />
            {results.map((renter, index: number) => (
              <DriverSearchResult
                onClose={onClose}
                testId={`driverSearchResult-${index}`}
                searchResult={renter}
                key={index}
                onAddDriver={(renter: SearchResult) => handleAddDriver(renter)}
                phoneTypeDomain={phoneTypeDomain}
              />
            ))}
          </StyledList>
        )}
        {results && results.length === 0 && (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='driverSearch.noResultsTitle'
              noResultsDescription='driverSearch.noResultsAddInfo'
              isBackgroundColor={false}
              handleNavigate={() => navigateToDriverForm(driverData)}
            />
          </Box>
        )}
      </MarginWrapper>
      <ProgressOverlay inProgress={loading || isPhoneDomainLoading} />

      {showChangePrimaryDriverDialog && (
        <ConfirmationDialog
          open={showChangePrimaryDriverDialog}
          onConfirm={handleConfirmChangePrimaryDriver}
          onCancel={handleCancelChangePrimaryDriver}
          title={t('driver.changePrimaryDriver')}
          description={t('driver.additionalDrivers.changePrimaryPrompt')}
          options={[
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ]}
        />
      )}
    </>
  );
};
