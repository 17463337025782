import { EMPTY_VALUE } from 'utils/constants';

export enum AddFromRateSourceFormFields {
  BillingNumber = 'billingNumber',
}

export interface AddFromRateSourceFormValues {
  [AddFromRateSourceFormFields.BillingNumber]: string;
}

export const ADD_FROM_RATE_SOURCE_INITIAL_VALUES: AddFromRateSourceFormValues = {
  [AddFromRateSourceFormFields.BillingNumber]: EMPTY_VALUE,
};

export const BILLING_ACCOUNT = 'BILLING_ACCOUNT';
