import { styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import Grid from '@mui/material/Grid';

export const SearchContainer = styled('div')(() => ({
  backgroundColor: ehiTheme.palette.background.default,
  borderBottom: `1px solid ${ehiTheme.ehi.palette.border.main}`,
  padding: ehiTheme.spacing(2),
}));

export const SearchButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: 'center';
`;

export const FlexContainer = styled('div')<{ $justifyContent?: string }>`
  display: flex;
  justify-content: ${(props) => props.$justifyContent || 'flex-start'};
`;

export const DriverListItem = styled('div')(() => ({
  display: 'block !important',
}));

const gridItemProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(0),
  },
}));
StyledGridItem.defaultProps = gridItemProps;
