import { CORPORATE_COUNTRIES } from 'components/shared/forms/fomats';
import { Option } from 'components/shared/ui/OptionTypes';
import { TFunction } from 'i18next';
import { Countries, CountrySubdivision } from 'services/location/locationTypes';
import { parseUrn } from 'utils/urnUtils';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { EMPTY_VALUE } from 'utils/constants';

/**
 * This will convert a string Enum to a list of Options for a Select Form Control.
 * Enum needs to have a string value of the translation key.
 *
 * @param {any} anEnum A string enum class
 * @param {TFunction} translate Function to translate string value to display
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateEnumMenuItems = (anEnum: { [p: number]: string }, translate: TFunction): Option[] => {
  return Object.keys(anEnum).map((key: any) => {
    return {
      label: translate(anEnum[key]),
      value: anEnum[key],
    };
  });
};

/**
 * This will convert the list of countries from a successful Location API call
 * to a list of Options for a Select Form Control.
 * After converting them to Options, it takes the corporate countries and puts them at the top of the list.
 *
 * @param {Country[]} countries array of countries retrieved from store
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateAllCountryMenuItemsWithCorporate = (countries: Countries): Option<string>[] => {
  return countries
    .map((country) => {
      const { name: label, countryIso3Code: value } = country;

      return {
        value: value ?? '',
        label: label ?? '',
        corporate: CORPORATE_COUNTRIES.indexOf(value as any) > -1,
      };
    })
    .sort((a, b) => +b.corporate - +a.corporate || a.label.localeCompare(b.label));
};

/**
 * This will convert the list of countries from a successful Location API call
 * to a list of Option Items with only corporate countries to be used for Select Drop Down.
 *
 * @param {Country[]} countries array of countries retrieved from store
 * @return {OptionItem[]} Array of Options to be used in Select Drop Down
 */
export const generateOptionItemsWithCorporateCountries = (countries: Countries): OptionItem[] => {
  return countries
    .filter(
      (country) =>
        !!Object.values(CORPORATE_COUNTRIES).find((corporateCountry) => corporateCountry === parseUrn(country.urn))
    )
    .map((country) => {
      const { name: label, urn } = country;

      return {
        id: parseUrn(urn),
        label: label ?? EMPTY_VALUE,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * This will convert the list of countries from a successful Location API call
 * to a list of Option Items with all non-corporate countries to be used for Select Drop Down.
 *
 * @param {Country[]} countries array of countries retrieved from store
 * @return {OptionItem[]} Array of Options to be used in Select Drop Down
 */
export const generateOptionItemsWithNonCorporateCountries = (countries: Countries): OptionItem[] => {
  return countries
    .filter(
      (country) =>
        !Object.values(CORPORATE_COUNTRIES).find((corporateCountry) => corporateCountry === parseUrn(country.urn))
    )
    .map((country) => {
      const { name: label, urn } = country;

      return {
        id: parseUrn(urn),
        label: label ?? EMPTY_VALUE,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * This will convert the list of subdivisions from a successful API call
 * to a list of Options for a Select Form Control.
 *
 * @param {CountrySubdivision[]} countrySubdivisions array of country subdivisions retrieved from store
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateAllCountrySubDivisionMenuItems = (countrySubdivisions: CountrySubdivision[]): Option<string>[] => {
  return countrySubdivisions
    ?.map((countrySubdivisions) => {
      return {
        label: countrySubdivisions.name ?? '',
        value: parseUrn(countrySubdivisions.urn) ?? '',
      };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));
};
