// Need to allow useQueries to be imported so that we can wrap it
// eslint-disable-next-line no-restricted-imports
import { useQueries, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { QueriesOptions } from '@tanstack/react-query/build/lib/useQueries';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';

export type UseMemoizedQueryResult<T> = Pick<
  UseQueryResult<T>,
  'data' | 'error' | 'isFetching' | 'isError' | 'isSuccess' | 'refetch'
>;
export type UseMemoizedQueriesResult<T> = {
  data?: T;
  errors?: ResponseMessage[];
  isError?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
  refetch: () => void;
};

export const _shallowObjectCompare = <T extends object>(a: T, b: T): boolean => {
  return (
    Object.keys(a).length === Object.keys(b).length &&
    Object.keys(a).every((key: string) => (a as any)[key] === (b as any)[key])
  );
};

export const _shallowArrayCompare = <T extends object>(a: T[], b: T[]): boolean => {
  return a.length === b.length && a.every((item, index) => _shallowObjectCompare(item, b[index]));
};

/**
 * Returns back the memorized query results
 * @param array the list of query results
 */
const useMemoizedQueryResults = <T>(array: UseQueryResult<T>[]): UseMemoizedQueryResult<T>[] => {
  const value = useMemo(() => array.map((item) => item as UseMemoizedQueryResult<T>), [array]);
  const prevValue = useRef(value);

  return useMemo(() => {
    if (_shallowArrayCompare(value, prevValue.current)) {
      return prevValue.current;
    }
    prevValue.current = value;
    return value;
  }, [value]);
};

/**
 * Combines previous and current data that is passed in
 * @param previous the previous data
 * @param current the current data
 */
const _combineData = <T extends any[]>(previous?: T, current?: T) => {
  if (!current) {
    return previous;
  }

  if (Array.isArray(current)) {
    return [...(previous || []), ...(current || [])];
  } else {
    return [...(previous || []), current];
  }
};

/**
 * Combines query results once useQueries is finished
 * @param results the valid results from useQueries
 */
export const _combineMemoizedQueryResults = <T extends any[]>(
  results: UseMemoizedQueryResult<T>[]
): UseMemoizedQueriesResult<T> => {
  if (!results || results.length === 0) {
    return {
      data: undefined,
      error: undefined,
      isLoading: false,
      refetch: () => {
        /* do-nothing */
      },
    } as UseMemoizedQueriesResult<T>;
  }

  return results.reduce(
    (previous: UseMemoizedQueriesResult<T>, current: UseMemoizedQueryResult<T>) =>
      ({
        data: _combineData(previous.data, current.data),
        errors: current.error ? [...(previous.errors || []), current.error] : previous.errors,
        isError: previous.isError || current.isError,
        isFetching: previous.isFetching || current.isFetching,
        isSuccess: current.isSuccess,
        refetch: current.refetch,
      } as UseMemoizedQueriesResult<T>),
    {} as UseMemoizedQueriesResult<T>
  );
};

/**
 * Use this instead of useQueries to prevent rerender loop when data changes
 * @param useQueriesParams contains all the queries to run as well as the context
 */
export const useMemoizedQueries = <T extends any[]>(useQueriesParams: {
  queries: readonly [...QueriesOptions<any>];
  context?: UseQueryOptions['context'];
}): UseMemoizedQueriesResult<T> => {
  const results: UseQueryResult[] = useQueries(useQueriesParams);
  const validResults = useMemoizedQueryResults(results) as UseMemoizedQueryResult<T>[];
  return useMemo(() => _combineMemoizedQueryResults<T>(validResults), [validResults]);
};
